<!-- eslint-disable -->
<template>
  <div>
    <v-container>
      <v-row justify="space-between" class="ml-0 pl-0">
        <v-col cols="12" md="3" sm="12" class="my-3 py-3 ml-0">
          <v-select v-model="empresaSelecionada" outlined color="nona" background-color="primeira" :items="setEmpresa"
            label="Selecione a empresa" class="c-select-empresa" return-object>

            <template v-slot:selection="{ item }">
              <span> {{ item.nomeGenerico }}</span>
            </template>

            <template v-slot:item="{ item }">
              <span class="primeira--text font-weight-bold">
                {{ item.nomeGenerico }}
              </span>
            </template>

          </v-select>
        </v-col>
        <v-col cols="12" md="9" sm="12">
          <v-switch v-model="isEditarProduto" color="#CA4745" class="mb-md-n4 mt-md-4 mt-sm-n10"></v-switch>
          <h6 class="mb-md-0 mt-md-0 mb-sm-10 mt-sm-n5">EDITAR PRODUTOS</h6>
        </v-col>
        <v-col cols="12" md="3" sm="12" id="c-select-un__global" class="mt-n8">
          <v-select class="c-select-un-global" attach="#c-select-un__global" menu-props="auto"
            v-model="unidadeMedidaSelecionada" outlined color="nona" background-color="primeira" :items="UnidadeMedida"
            label="Unidade de Medida Global" persistent-hint
            hint="Caso a opção seja CX, o Fator (Fator de multiplicação pela unidade de estoque) que será enviado, será a quantidade marcada na opção 'Quant. Por Caixa'. Qualquer outra opção o Fator enviado será sempre 1.">
            <template v-slot:selection="{ item }">
              {{ Object.values(item)[0] }}
            </template>
            <template v-slot:item="{ item }">
              <v-card width="100%" flat class="ma-0 pa-0" color="quinta">
                <v-list dark dense color="quinta" class="ma-0 pa-0">
                  <v-list-item v-for="(valor, idx) in item" :key="idx">
                    <v-list-item-title class="font-weight-bold pa-0 ma-0">{{ valor }} - {{ idx }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="4" sm="12" class="mt-n8">
          <v-text-field class="c-tab-produto__periodoRef" type="date" v-model="inventario.periodoRef"
            :value="inventario.periodoRef" label="Período" hint="Período referência. Ano e mês"
            persistent-hint></v-text-field>
        </v-col>
        <!-- <v-col cols="12" md="4" sm="12" class="mt-n8">
          <v-text-field type="number" v-model.number="inventario.diasDeEstoque" :value="inventario.diasDeEstoque"
            label="Dias de Estoque" hint="Utilizar como base a demanda dos últimos 30 dias"
            persistent-hint></v-text-field>
        </v-col> -->
        <v-col cols="12" md="12" sm="12">
          <v-dialog v-model="openHistorico" fullscreen>
            <v-card dark color="quinta">
              <v-card-title class="oitava--text">Historico de Inventário</v-card-title>
              <v-btn color="quinta" small absolute right float fab dark @click="openHistorico = false"
                class="mt-n13 mr-3">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <v-card-text class="ma-0 pa-0">
                <historicoInventario :openHistorico="openHistorico"></historicoInventario>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table @item-selected="identificarSelecao" selectable-key="objectId" sort-by="objectId"
      v-model="produtosSelecionados" :items="produtos" :single-select="selecaoUnica" :headers="cabecalhoTabela"
      item-key="objectId" :search="search" class="quinta elevation-1 pt-5 c-tabela-produtos" show-select
      calculate-widths :loading="custoLoading" :loader-height="0">
      <template v-slot:top color="primeira">
        <v-progress-linear absolute top :height="8" :active="custoLoading" :indeterminate="custoLoading" color="#727272"
          class="pt-1"></v-progress-linear>
        <v-toolbar flat color="quinta">
          <v-toolbar-title>Produto</v-toolbar-title>
          <v-text-field class="mx-3" v-model="search" append-icon="mdi-magnify" label="Buscar Produto" single-line
            hide-details></v-text-field>
          <v-btn @click="openHistorico = true" class="mx-5" fab color="primeira" dark>
            <v-icon>article</v-icon>
          </v-btn>
          <v-btn :loading="enviadoInventario" color="primeira" fab class="mx-5" @click="enviarSellOut">
            <v-icon large>cloud_sync</v-icon>
          </v-btn>
          <!-- <v-divider class="mx-1" inset vertical></v-divider>
          <v-spacer></v-spacer>-->

          <!--  Dialogo para editar ou add produto -->
          <v-dialog overlay-color="primeira" v-model="dialog" max-width="100%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primeira" fab v-bind="attrs" v-on="on">
                <v-icon large>add</v-icon>
              </v-btn>
            </template>
            <v-card dark color="primeira">
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.material" label="Nome do Produto"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.segmento" label="Segmento"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model.number="editedItem.codigo" label="Código"
                        @keypress="getValorCodigo($event, editedItem.codigo)"
                        @keyup="removTeclaEcodigo($event, editedItem)"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model.number="editedItem.qtdPorCx" label="Quantidade Por Caixa"
                        @keypress="getValorEstoque($event, editedItem.qtdPorCx)"
                        @keyup="removTeclaEstoque($event, editedItem)"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model.number="editedItem.unidade" label="Unidade em Estoque"
                        @keypress="getValorUnidade($event, editedItem.unidade)"
                        @keyup="removTeclaEunidade($event, editedItem)"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field type="number" v-model.number="editedItem.diasDeEstoque" label="Dias de Estoque"
                        @keypress="
                          getValordiasDeEstoque(
                            $event,
                            editedItem.diasDeEstoque
                          )
                          " @keyup="removTeclaEdiasDeEstoque($event, editedItem)"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.cst" label="CST"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions dark color="primeira">
                <v-spacer></v-spacer>
                <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="darken-1" text @click="save">Salvar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Dialogo para deletar produto -->
          <v-dialog dark v-model="dialogDelete" max-width="500px">
            <v-card color="primeira">
              <v-card-title class="text-h5">Tem certeza de que deseja excluir este produto?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- Dados do cabeçalho -->
      <template v-slot:header.actions="props">
        {{ (props.header.cellClass = isEditarProduto ? "" : "u-hide") }}
        {{ (props.header.class = isEditarProduto ? "" : "u-hide") }}
        {{ props.header.text }}
      </template>

      <!-- Ocutando Edição BTN quantidade por caixa -->
      <template v-slot:header.qtdPorCx="props">
        {{ (props.header.cellClass = statusUnidadeMedida ? "" : "u-hide") }}
        {{ (props.header.class = statusUnidadeMedida ? "" : "u-hide") }}
        {{ props.header.text }}
      </template>

      <template v-slot:header.material="props">
        <span class="u-hide">{{
          (props.header.width = isEditarProduto ? "50%" : "300px")
        }}</span>
        {{ props.header.text }}
      </template>

      <template v-slot:header.unidade="props">
        <span class="u-hide">{{
          (props.header.width = isEditarProduto ? "15%" : "15%")
        }}</span>
        {{ props.header.text = 'Caixa' in unidadeMedidaSelecionada ? 'Unidades de caixas no Estoque' : 'Unidades no Estoque'}}
      </template>

      <template v-slot:header.diasDeEstoque="props">
        <span class="u-hide">{{
          (props.header.width = isEditarProduto ? "15%" : "15%")
        }}</span>
        {{ props.header.text }}
      </template>

      <!-- Dados da tabela -->

      <template v-slot:item.diasDeEstoque="props">
        <v-text-field :filled="props.item.naoSelecionado" :disabled="props.item.naoSelecionado" type="number"
          v-model.number="props.item.diasDeEstoque" single-line prepend-icon="remove" append-icon="add"
          @click:append="props.item.diasDeEstoque++" @click:prepend="props.item.diasDeEstoque--"
          @keyup="removTeclaEestoque($event, props.item)"></v-text-field>
      </template>

      <template v-slot:item.unidade="props">
        <v-text-field :filled="props.item.naoSelecionado" :disabled="props.item.naoSelecionado" type="number"
          v-model.number="props.item.unidade" single-line prepend-icon="remove" append-icon="add"
          @click:append="props.item.unidade++" @click:prepend="props.item.unidade--"
          @keyup="removTeclaE($event, props.item)">
        </v-text-field>
      </template>

      <template v-slot:item.qtdPorCx="props">
        <v-text-field :filled="props.item.naoSelecionado" :disabled="props.item.naoSelecionado" type="number"
          v-model.number="props.item.qtdPorCx" single-line prepend-icon="remove" append-icon="add"
          @click:append="props.item.qtdPorCx++" @click:prepend="props.item.qtdPorCx--"
          @keyup="removTeclaECaixa($event, props.item)"></v-text-field>
      </template>
      <template v-slot:item.material="{ item }">{{ item.material }}</template>
      <template v-slot:item.segmento="{ item }">{{ item.segmento }}</template>
      <template v-slot:item.cst="{ item }">{{ item.cst }}</template>

      <!--   Botões para Editar Produtos -->
      <template v-if="isEditarProduto" v-slot:item.actions="{ item }">
        <v-fade-transition appear>
          <v-btn color="#727272" @click="editItem(item)" small fab class="my-2">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-fade-transition>
        <v-fade-transition appear>
          <v-btn color="#727272" @click="deleteItem(item)" small fab class="ml-4 my-2">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-fade-transition>
      </template>

      <!--     Botão para carregar produtos -->
      <template v-slot:no-data>
        <v-btn color="primeira" @click="initialize">Carregar dados</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import { urlApi, showError, cofingK, apiMagically } from "@/global";
import historicoInventario from "@/components/historico/historicoInventario.vue";
export default {
  name: "tabelaClientes",
  props: { dialogoProduto: Boolean },
  components: { historicoInventario },
  data: (vm) => ({
    enviadoInventario: false,
    openHistorico: false,
    empresaSelecionada: {},
    setEmpresa: [
      {
        sold: 5040874,
        key: "80177262-9E41-467F-9E5A-96521191DAB4",
        grupo: "Nutrimix",
        nome: "Nutrimix Comercial Ltda",
        nomeGenerico: "Nutrimix",
      },
      {
        sold: 6029808,
        key: "1E9E8E68-8952-45E0-AD01-448C4BBB4420",
        grupo: "Nutrimix",
        nome: "Nutriplan Comercial Ltda",
        nomeGenerico: "Nutriplan",
      },
      {
        sold: 7249373,
        key: "0E40E860-1951-4C5D-814E-F6E360763A06",
        grupo: "Nutrimix",
        nome: "Nutrimix Brasília Comercial Ltda",
        nomeGenerico: "Nutrimix Brasília",
      }
    ],
    dataNutrimix: {
      sold: 5040874,
      key: "80177262-9E41-467F-9E5A-96521191DAB4",
      grupo: "Nutrimix",
      nome: "Nutrimix Comercial Ltda",
    },
    unidadeMedidaSelecionada: { Unidade: "UN" },
    statusUnidadeMedida: true,
    UnidadeMedida: [
      { Litros: "LT" },
      { Unidade: "UN" },
      { Sache: "SA" },
      { Display: "DI" },
      { Kilos: "KG" },
      { Caixa: "CX" },
    ],
    isEditarProduto: false,
    custoLoading: false,
    inventario: {
      diasDeEstoque: 0,
      periodoRef: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    },
    produtosSelecionados: [],
    selecaoUnica: false,
    search: "",
    dialog: false,
    dialogDelete: false,
    cabecalhoTabela: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "objectId",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Nome do Produto",
        align: "start",
        sortable: true,
        value: "material",
        width: "30%",
      },
      {
        text: "Quant. Por Caixa",
        align: "end",
        sortable: true,
        value: "qtdPorCx",
        width: "15%",
        class: `${vm.statusUnidadeMedida ? "c-btn-editar_visivel" : "u-hide"}`,
        cellClass: `${vm.statusUnidadeMedida ? "c-btn-editar_visivel" : "u-hide"
          }`,
      },
      {
        text: "Dias de Estoque",
        align: "right",
        sortable: true,
        value: "diasDeEstoque",
        cellClass: "c-tab-produto__col__input",
        width: "20%",
      },
      {
        text: "Unidades no Estoque",
        align: "right",
        sortable: true,
        value: "unidade",
        class: "c-tab-produto-unidade",
        cellClass: "c-tab-produto__col__input",
        width: "30%",
      },
      {
        text: "Editar Produtos",
        align: "right",
        value: "actions",
        sortable: false,
        width: "42%",
        class: `${vm.isEditarProduto ? "u-hide" : "c-btn-editar_visivel"}`,
        cellClass: `${vm.isEditarProduto ? "u-hide" : "c-btn-editar_visivel"}`,
      },
      {
        text: "Código",
        sortable: false,
        value: "codigo",
        class: "u-hide",
        cellClass: "u-hide",
        width: "0%",
      },
      {
        text: "Segmento",
        align: "start",
        sortable: false,
        value: "segmento",
        class: "u-hide",
        cellClass: "u-hide",
        width: "0%",
      },
      {
        text: "CST",
        align: "start",
        sortable: false,
        value: "cst",
        class: "u-hide",
        cellClass: "u-hide",
        width: "0%",
      },
    ],
    produtos: [],
    quantEstoque: 0,
    editedIndex: -1,
    editedItem: {
      material: "",
      segmento: "",
      codigo: "",
      qtdPorCx: 0,
      cst: "",
      unidade: 0,
      DiasDeEstoque: 0,
    },
    defaultItem: {
      material: "",
      segmento: "",
      codigo: "",
      qtdPorCx: 0,
      cst: "",
      unidade: 0,
      DiasDeEstoque: 0,
    },
    valorEstoque: 0,
    valorUnidade: 0,
    valorCodigo: 0,
    diasDeEstoqueValor: 0,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Produto" : "Editar Produto";
    },
  },
  watch: {
    dialogoProduto(novo) {
      if (!novo) {
        this.empresaSelecionada = {};
        this.produtosSelecionados = [];
        this.produtos = [];
        this.unidadeMedidaSelecionada = { Unidade: "UN" }
        this.inventario = {
          diasDeEstoque: 0,
          periodoRef: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        }

        /* this.editedItem = {
          material: "",
          segmento: "",
          codigo: "",
          qtdPorCx: 0,
          cst: "",
          unidade: 0,
          DiasDeEstoque: 0,
        }
        this.defaultItem = {
          material: "",
          segmento: "",
          codigo: "",
          qtdPorCx: 0,
          cst: "",
          unidade: 0,
          DiasDeEstoque: 0,
        } */

      }
    },
    unidadeMedidaSelecionada: {
      handler(novo) {
        if (Object.values(novo)[0] === "CX") {
          this.statusUnidadeMedida = true;
        } else {
          this.statusUnidadeMedida = false;
        }
      },
      deep: true,
      immediate: true,
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    fecharInventario() {
      this.$emit("fecharInventario", false);
    },
    getValordiasDeEstoque(e, v) {
      if (e.key === "e") this.diasDeEstoqueValor = v;
    },
    removTeclaEdiasDeEstoque(e, item) {
      if (e.key === "e") item.diasDeEstoque = this.diasDeEstoqueValor;
    },
    identificarSelecao(linha) {
      if (linha.value) {
        linha.item.naoSelecionado = false
      } else {
        linha.item.naoSelecionado = true
      }
    },

    /*  async getDataEHoraExatas() {
       try {
         const { data: { datetime } } = await axios.get('https://worldtimeapi.org/api/ip');
         const dataHoraCorretas = new Date(datetime);
         return dataHoraCorretas;
       } catch (e) {
         showError(`Erro ao obter a data e hora corretas${e}`)
       }
     }, */

     async getDataEHoraExatas() {
      try {
        const response = await axios.get('https://worldtimeapi.org/api/timezone/America/Sao_Paulo');
        const { datetime } = response.data;
        return new Date(datetime);
      } catch (e) {
        console.log(`Erro ao obter a data e hora da API. Usando hora do sistema ajustada para o Brasil. Erro: ${e}`);

        // Obtenha a data/hora do sistema e ajuste para o fuso horário do Brasil
        const dataHoraCorretas = new Date();
        const offset = -3 * 60; // UTC-3 (BRT)
        dataHoraCorretas.setMinutes(dataHoraCorretas.getMinutes() + dataHoraCorretas.getTimezoneOffset() + offset);
        return dataHoraCorretas;
      }
    },
    async criarHistorico(dadosHistorico) {
      let setEpresaEnvio = 'Empresa sem identificação'
      if (dadosHistorico.soldDistribuidor === 5040874) setEpresaEnvio = 'Nutrimix';
      if (dadosHistorico.soldDistribuidor === 6029808) setEpresaEnvio = 'Nutriplan';
      if (dadosHistorico.soldDistribuidor === 7249373) setEpresaEnvio = 'Nutrimix Brasília';

      const sendHistorico = {
        dataEnvio: await this.getDataEHoraExatas(),
        /* Como estava antes */
        /*  empresa: this.empresaSelecionada.nomeGenerico, */
        empresa: setEpresaEnvio,
        periodo: dadosHistorico.periodoRef,
        diasDeEstoque: dadosHistorico.diasDeEstoque,
        produtos: dadosHistorico.produtos,
        logs: {
          email: apiMagically.logEmailUser,
          linkApi: apiMagically.urlsApi.urlInventario
        }
      };
      axios
        .post(`${urlApi}/criar-historico-inventario`, sendHistorico, {
          headers: cofingK,
        })
        .then(() => {
          this.enviadoInventario = false;
          this.$toasted.global.defaultSuccess();
          this.fecharInventario()
        })
        .catch(() => this.enviadoInventario = false);
    },
    removTeclaE(e, v) {
      if (e.key === "e") v.unidade = 0;
    },
    removTeclaECaixa(e, v) {
      if (e.key === "e") v.qtdPorCx = 0;
    },
    getValorCodigo(e, v) {
      if (e.key === "e") this.valorCodigo = v;
    },
    removTeclaEcodigo(e, v) {
      if (e.key === "e") v.codigo = this.valorCodigo;
    },
    getValorUnidade(e, v) {
      if (e.key === "e") this.valorUnidade = v;
    },
    removTeclaEunidade(e, v) {
      if (e.key === "e") v.unidade = this.valorUnidade;
    },
    getValorEstoque(e, v) {
      if (e.key === "e") this.valorEstoque = v;
    },
    removTeclaEstoque(e, item) {
      if (e.key === "e") item.qtdPorCx = this.valorEstoque;
    },
    deletarProduto(idProduto) {
      let deleteProduto = {};
      deleteProduto.objectId = idProduto;
      axios
        .post(`${urlApi}/deletar-produto`, deleteProduto, {
          headers: cofingK,
        })
        .then(() => {
          this.closeDelete();
          this.$toasted.global.defaultSuccess({
            msg: "Produto excluido com sucesso!",
          });
        })
        .catch(showError);
    },
    criarProduto(produtoParaCriar) {
      axios
        .post(`${urlApi}/criar-produto`, produtoParaCriar, {
          headers: cofingK,
        })
        .then((res) => {
          this.editedItem.objectId = res.data.result;
          this.produtos.push(this.editedItem);
          this.$toasted.global.defaultSuccess({
            msg: "Produto Criado com sucesso!",
          });
          this.close();
        })
        .catch(showError);
    },
    enviarSellOut() {
      this.enviadoInventario = true;

      if (Object.keys(this.empresaSelecionada).length === 0) {
        showError("Selecione uma empresa!");
        this.enviadoInventario = false;
        return;
      }
      /*      this.alterarProduto(this.produtosSelecionados); */
      this.inventario.secrectKeyDistribuidora = this.empresaSelecionada.key;
      this.inventario.soldDistribuidor = this.empresaSelecionada.sold;
      this.inventario.grupoDistribuidor = this.empresaSelecionada.grupo;
      this.inventario.nomeDistribuidor = this.empresaSelecionada.nome;

      if (this.produtosSelecionados.length == 0) {
        showError("Selecione um produto!");
        this.enviadoInventario = false;
        return;
      }
      this.inventario.produtos = [];
      this.produtosSelecionados.filter((e) => {
        this.inventario.produtos.push({
          codProdutoNestle_CodEAN: e.codigo,
          nomeProduto: e.material,
          // novo metodo para seleção global, por padrão será unidade. Possibilidade de edição global.
          diasDeEstoque: e.diasDeEstoque,
          unidades: e.unidade,
          UnidadeMedida: Object.values(this.unidadeMedidaSelecionada)[0],
          fator: this.statusUnidadeMedida ? e.qtdPorCx : 1,
        });
      });
      let dataInventario = {};
      dataInventario = JSON.stringify(this.inventario);
      axios
        .post(`${apiMagically.urlsApi.urlInventario}`, dataInventario, {
          headers: {
            "x-api-version": 1,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.enviadoInventario = false;
          const respostaApiMagical = `${res.data.message} Status ${res.data.status} `;
          this.$toasted.global.defaultSuccess({ msg: respostaApiMagical });
          this.produtosSelecionados.filter((e) => {
            e.unidade = 0;
            e.diasDeEstoque = 0;
          });
          this.produtosSelecionados = [];
          this.criarHistorico(this.inventario);
        })
        .catch((e) => {
          this.enviadoInventario = false;
          showError(e.response.data.message);
        });
    },
    /*  enviarSellOut() {
      this.alterarProduto(this.produtosSelecionados);
      this.inventario.produtos = [];
      this.produtosSelecionados.filter(e => {
        this.inventario.produtos.push({
          codProdutoNestle_CodEAN: e.codigo,
          nomeProduto: e.material,
          UnidadeMedida: e.qtdPorCx > 1 ? "CX" : "UN",
          unidades: e.unidade,
          fator: e.qtdPorCx
        });
      });
      this.inventario.secrectKeyDistribuidora =
        "80177262-9E41-467F-9E5A-96521191DAB4";
      this.inventario.soldDistribuidor = 5040874;
      this.inventario.grupoDistribuidor = "Nutrimix";
      this.inventario.nomeDistribuidor = "Nutrimix Comercial Ltda";
      axios
        .post(
          `https://apinestleselloutintegracaodev.magically.com.br/api/Inventario`,
          this.inventario,
          {
            headers: {
              "x-api-version": 1,
              'Access-Control-Allow-Origin': 'https://gestor.nutrimix.app/',
              'Access-Control-Allow-Headers': 'Content-Type'
            }
          }
        )
        .then(res => {
          const respostaApiMagical = `${res.data.message} Status ${res.data.status} `;
          this.$toasted.global.defaultSuccess({ msg: respostaApiMagical });
        })
        .catch(e => showError(e.response.data.message));
    }, */
    alterarProduto(produtosParaAlterar) {
      axios
        .post(`${urlApi}/alterar-lista-produtos`, produtosParaAlterar, {
          headers: cofingK,
        })
        .then((res) => {
          this.$toasted.global.defaultSuccess({ msg: res.data.result });
        })
        .catch(showError);
    },
    initialize() {
      this.custoLoading = true;
      axios
        .post(`${urlApi}/listar-produtos`, this.user, { headers: cofingK })
        .then((res) => {
          res.data.result.forEach(el => {
            el.naoSelecionado = true;
          })
          this.produtos = res.data.result;
          this.custoLoading = false;
        })
        .catch(showError);
    },

    editItem(item) {
      this.editedIndex = this.produtos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.produtos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.produtos.splice(this.editedIndex, 1);
      this.deletarProduto(this.editedItem.objectId);
    },

    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        const produtoEditado = Object.assign(
          this.produtos[this.editedIndex],
          this.editedItem
        );
        this.alterarProduto(produtoEditado);
        this.close();
      } else {
        this.criarProduto(this.editedItem);
      }
    },
  },
};
</script>

<style>
.c-select-un-global .v-messages__message {
  line-height: 14px;
  hyphens: none;
}

.c-tabela-produtos tbody tr>td:first-of-type {
  width: 4%;
}

.c-tab-produto__col__input input::-webkit-outer-spin-button,
.c-tab-produto__col__input input::-webkit-inner-spin-button {
  display: none;
  margin: 0px;
}

.c-tab-produto__col__input input[type="number"] {
  -moz-appearance: textfield;
}

.c-tab-produto__periodoRef input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  margin: 0px;
  padding: 0px;
}

.c-tabela-produtos {
  position: relative;
}

.c-select-empresa .v-select-list {
  background: rgb(88 88 88 / 91%) !important;
}

#c-select-un__global {
  position: relative;
}

#c-select-un__global .v-menu__content {
  left: 0px !important;
  min-width: 100% !important;
}

#c-select-un__global .v-select-list {
  background: #ab3331 !important;
  padding: 0px !important;
}
</style>
