<template>
  <v-container fluid class="segunda ma-0 pa-0 c-header__topo">
    <v-progress-linear :active="pregresso" top absolute indeterminate color="white darken-2"></v-progress-linear>
    <v-scroll-y-transition appear v-if="dadosUser.name !== undefined">
      <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="d-flex flex-row flex-nowrap c-header__col">
        <!-- LOGO DA EMPRESA -->
        <div class="c-logo__col">
          <v-img lazy-src="@/assets/log.webp" class="c-header__logo" src="@/assets/logo-nutrimix-distribuidora.svg"
            contain max-height="auto" max-width="150"></v-img>
        </div>
        <v-spacer></v-spacer>
        <span class="ml-4 c-buscar-card">
          <!-- BTN INVENTARIO -->
          <v-btn color="secondary" @click="openInventario()" class="mr-5">INVENTÁRIO<v-icon
              right>category</v-icon></v-btn>
        </span>
        <span class="mr-5 c-buscar-card">
          <v-btn color="secondary" fab @click="openConfig()" small
            class="mt-n1"><v-icon>settings</v-icon></v-btn><v-dialog v-model="dialogConfig" width="100%">
            <v-card dark>
              <v-card-title class="secondary white--text">
                DEFINIR URL API MAGICALLY
              </v-card-title>

              <v-card-text class="pa-7 quinta">
                <v-text-field outlined label="URL para exportação do inventário"
                  v-model="urlApiMagically.urlsApi.urlInventario" prepend-inner-icon="link"
                  hint="Coloque a URL da API com final /inventario" persistent-hint color="white"></v-text-field>
                <v-text-field outlined class="mt-8" label="URL para exportação das vendas(Sell-out)"
                  v-model="urlApiMagically.urlsApi.urlImportacao" prepend-inner-icon="link"
                  hint="Coloque a URL da API com final /importacao" persistent-hint color="white"></v-text-field>
                <v-card-actions class="mr-0 pr-0">
                  <v-spacer />
                  <v-btn @click="alteraUrlApiMagically()" fab color="secondary"><v-icon large
                      color="white">save</v-icon></v-btn>
                </v-card-actions>
              </v-card-text></v-card>
          </v-dialog>
        </span>
        <v-menu right bottom transition="slide-y-transition">
          <template v-slot:activator="{ on }">
            <v-btn class="secondary mt-n1 mr-5" v-on="on" fab small>
              <v-icon v-if="dadosUser.admin" dark color="segunda">mdi-shield-account</v-icon>
              <v-icon v-else color="white">person</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-5">
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="#943634" class="mb-2">
                  <v-icon dark>mdi-account-circle</v-icon>
                </v-avatar>
                <h3>{{ dadosUser.name }}</h3>
                <p class="text-caption mt-1">{{ dadosUser.email }}</p>
                <v-divider class="my-3"></v-divider>

                <modalGestaoUsuario />

                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text color="#943634" @click="logout">
                  <v-icon left dark color="#943634">logout</v-icon>Sair
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-col>
    </v-scroll-y-transition>

  </v-container>
</template>

<script>
import axios from "axios";
import { cofingK, urlApi, showError, userKey, apiMagically } from "@/global";
import modalGestaoUsuario from "../usuario/modalGestaoUsuario";
import barramento from "@/barramento";
export default {
  name: "Header-screen",
  components: { modalGestaoUsuario },
  props: {
    dadosUser: Object,
  },
  data: function () {
    return {
      pregresso: true,
      urlApiMagically: {
        urlsApi: {
          urlInventario: "",
          urlImportacao: "",
        },
      },
      dialogConfig: false,
      user: {
        initials: "JD",
        fullName: this.dadosUser.name,
        email: this.dadosUser.email,
        openMenu: true,
      },
    };
  },
  watch: {},
  methods: {
    getConfigSistema() {
      this.pregresso = true
      axios
        .post(
          `${urlApi}/get-dados-sistema`,
          {}
        )
        .then((res) => {
          this.urlApiMagically.urlsApi = res.data.result[0].urlsApi;
          this.urlApiMagically.objectId = res.data.result[0].objectId;
          setTimeout(() => {
            // Após 2 segundos, altera o valor de pregresso para false
            this.pregresso = false
          }, 2000);

        })
        .catch(e => {
          showError(e)
          this.pregresso = true
        });
    },
    alteraUrlApiMagically() {
      apiMagically.urlsApi = this.urlApiMagically.urlsApi;
      axios
        .post(`${urlApi}/alterar-url-api`, this.urlApiMagically, {
          headers: cofingK,
        })
        .then(() => {
          this.dialogConfig = false
          this.$toasted.global.defaultSuccess({
            msg: "Url alterada com sucesso!",
          });
        })
        .catch(showError);
    },
    openInventario() {
      barramento.enviarOpenInventario(true);
    },
    openConfig() {
      this.dialogConfig = true;
    },
    resetConsultaCard() {
      barramento.enviadoPesquisaCard("");
    },
    openNovaLicitacao() {
      barramento.enviarNovalicitacao();
    },
    addFases() {
      barramento.enviarAddFase();
    },
    disRemoverFases() {
      barramento.enviarRemoverFases();
    },
    logout() {
      localStorage.removeItem(userKey);
      barramento.enviarStatusLogin(false, true);
    },
  },
  mounted() { },
  created() {
    this.getConfigSistema();
  },
};
</script>

<style>
/* .c-header {
  grid-area: c-header;
  background: transparent;
  padding: 15px 25px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
} */
.c-color_text__segunda input {
  color: #363494 !important;
}

.c-header {
  background: transparent;
  padding: 15px 25px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.c-header__switch label {
  font-size: 14px;
  color: #363494 !important;
}
</style>