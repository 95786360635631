<template>
  <div id="c-content" class="c-content c-content__principal">
    <div class="segunda" v-if="usuarioLogado">
        <sellOut :dadosUser="dadosUser"></sellOut>
    </div>
   <!--  <v-container>
      <v-row>
        <v-col>

        </v-col>
      </v-row>
    </v-container> -->

    <v-container v-else class="fill-height c-login">
      <v-fade-transition appear>
       <!--  <v-row class="d-flex flex-column justify-center align-center"> -->
        <v-row>
          <v-col cols="12">
            <login />
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-container>
  </div>
</template>

<script>
import login from "../usuario/login";
import sellOut from "../sellout/sellOut";

export default {
  name: "Content-screen",
  components: { sellOut, login},
  props: {
    usuarioLogado: Boolean,
    sessaoExpirada: Boolean,
    dadosUser: Object
  }
};
</script>
<style scoped>
.c-login {
    position: fixed;
    min-width: 100%;
}
.c-content__login {
    position: fixed;
    left: 0px;
    right: 0px;
}
</style>