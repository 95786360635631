<template>
    <div>
        <v-autocomplete v-model="pontoSelecionado" :filter="filtroPontoEntrega" color="primary" :items="clientesBd" item-text="razaoSocial"
            item-value="objectId" :search-input.sync="search" cache-items flat hide-no-data hide-details
            label="Pontos de entrega" solo-inverted clearable autocomplete="off">
            <template v-slot:item="{ item }">

                <v-chip link label dark color="quarta" class="c-item">
                    <span><v-icon>apartment</v-icon> {{ item.razaoSocial }}</span><v-divider class="mx-4" vertical />
                    <span class="c-item-empresa"><v-icon>assignment</v-icon> {{ item.logradouro }} N°{{ item.numero }}, {{
                        item.cidade }}</span> <v-divider class="mx-4" vertical />
                    <span class="c-item-empresa">CNPJ: {{ item.cnpjCpf }}</span>
                </v-chip>

            </template>
        </v-autocomplete>
       
    </div>
</template>
  
<script>
export default {
    name: "PontoDeEntrega",
    props: { clientesBd: Array },
    components: {},
    data: () => ({
        pontoSelecionado: '',
        items: [],
        search: null,
    }),
    computed: {
        dataPontoSelecionado() {
            return data => this.clientesBd.filter(e => e.objectId == data);
        },
    },
    watch: {
        pontoSelecionado: {
            handler(novo, antigo) {
                if (novo != antigo && novo != '') {
                    let ponto = []
                    ponto = this.clientesBd.filter(e => e.objectId == novo);
                    this.dataPontoEntrega(ponto);
                }
            },
            immediate: true
        },
    },
    methods: {
        filtroPontoEntrega(item, queryText) {
            const buscandoEmpresa = item.razaoSocial.toLowerCase()
            const buscandoEndereco = item.logradouro.toLowerCase()
            const buscandoCnpj = item.cnpjCpf.replace(/[^\d]+/g,'').toLowerCase()
            const searchText = queryText.toLowerCase()

            return buscandoEmpresa.indexOf(searchText) > -1 ||
            buscandoEndereco.indexOf(searchText) > -1 ||
            buscandoCnpj.indexOf(searchText) > -1
        },
        dataPontoEntrega(dados) {
            this.$emit("dataPontoEntrega", dados);
        },
    },
    created() { },
    beforeMount() { },
    mounted() { }
};
</script>
  
<style>
.c-item {
    width: 100%;
}

.c-item-empresa {
    font-size: 11px;
}
</style>