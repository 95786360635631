<template>
    <v-card>
        <v-toolbar dark color="primary" class="mr-0 pr-0">
            <v-toolbar-title v-if="iEnd == 0" class="text-uppercase text-subtitle-1">
                <span>ENDEREÇO PADRÃO CLIENTE</span>
            </v-toolbar-title>
            <v-toolbar-title v-else class="text-uppercase text-subtitle-1">
                <v-text-field :key="entrega.objectId" prefix="EDITAR:" dense solo background-color="quarta"
                    v-model="entrega.nomeEndereco" hide-details label="Nome do Endereço" class="green">
                </v-text-field>
            </v-toolbar-title>
            <v-divider class="mx-4" vertical></v-divider>
            <!-- Switch Definir endereço de entrega padrão -->
            <v-chip color="quarta" class="text-uppercase pl-4">
                Endereço de entrega padrão
                <v-switch v-model="entrega.entregaPadrao" color="green" hide-details class="pl-2 pr-0 ma-0">
                </v-switch>
            </v-chip>
            <v-spacer></v-spacer>
            <v-toolbar-items class="pr-0 mr-0">
                <v-card-actions class="mr-0 pr-0">
                    <v-btn v-if="isAdd" color="quarta" class="px-5 mr-4" @click="salvarNovoPontoDeEntrega()">
                        <v-icon left>save</v-icon>
                        SALVAR ENDEREÇO
                    </v-btn>
                    <v-btn v-if="!isAdd && iEnd != 0" color="quarta" class="px-5 mr-4" @click="alterarPontoEntrega()">
                        <v-icon left>save</v-icon>
                        SALVAR ALTERAÇÃO
                    </v-btn>
                    <!-- BOTÃO DE FECHAR PAINEL DE EDITAR PONTO DE ENTREGA -->
                    <v-btn elevation="5" small fab color="secondary" @click="toggle" class="mr-0">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text :class="`${iEnd == 0 ? 'height250' : 'height500'}`">
            <v-container>
                <v-row>
                    <!-- DADOS APENAS DA IDENTIFICAÇÃO DA EMPRESA - SÓ APARECE QUANDO FOR DIFERENTE DO ENDEREÇO PADRÃO DO CLIENTE -->
                    <v-col v-if="iEnd != 0" cols="12" class="mx-0 px-0">
                        <v-row class="ma-0 pa-0">
                            <v-col v-if="isAdd" cols="12" class="mb-0">
                                <!-- Consultar novo endereço de entrega na lista de clientes -->
                                <PontosDeEntrega @dataPontoEntrega="setarDataPontoEntrega($event)" :clientesBd="clientes">
                                </PontosDeEntrega>
                            </v-col>
                            <v-col v-if="!isAdd" cols="12" class="py-7">
                                <v-row>
                                    <v-divider class="ma-2 linha-endereco" />
                                    <h6 class="text-caption text-uppercase"> Dados do ponto de entrega</h6> <v-divider
                                        class="ma-2 linha-endereco" />
                                </v-row>
                            </v-col>
                            <v-col cols="3" class="pb-0">
                                <v-text-field hide-details outlined v-model="entrega.codClienteEntrega"
                                    label="Cód. Cliente da Entrega"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pb-0">
                                <v-select hide-details outlined v-model="entrega.segmentoClienteEntrega"
                                    :items="selecSegmentoCliente" label="Segmento Cliente da Entrega"></v-select>
                            </v-col>
                            <v-col cols="3" class="pb-0">
                                <v-select hide-details outlined v-model="entrega.tipoClienteEntrega"
                                    :items="selecTipoClienteEntrega" label="Tipo de Pessoa da Entrega"
                                    class="c-select__pessoaFisica">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="7" class="mb-0">
                                <v-text-field v-model="entrega.razaoSocialEntrega" hide-details
                                    label="Razão Social da Entrega"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="5" class="mb-0">
                                <v-text-field :key="entrega.objectId" v-maska="['###.###.###-##', '##.###.###/####-##']"
                                    v-model="entrega.cnpjEntrega" hide-details label="CNPJ/CPF da Entrega"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Apenas o Endereço do ponto de entrega -->
                    <v-col v-if="iEnd != 0" cols="12" class="mx-0 px-0">
                        <v-row>
                            <v-col cols="12" class="py-5">
                                <v-row>
                                    <v-divider class="ma-2 linha-endereco" />
                                    <h6 class="text-caption text-uppercase"> Endereço do ponto de entrega</h6> <v-divider
                                        class="ma-2 linha-endereco" />
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="7">
                                <v-text-field outlined v-model="entrega.enderecoClienteEntrega" label="Endereço da Entrega"
                                    hint="Rua, número, complemento do cliente onde será entregue"
                                    persistent-hint></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-text-field outlined v-model="entrega.cidadeEntrega"
                                    label="Cidade da Entrega"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field outlined v-model="entrega.ufEntrega" label="UF da Entrega"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-text-field :key="9905" v-maska="'##.###-###'" outlined v-model="entrega.cepEntrega"
                                    label="CEP da Entrega"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
  
<script>
import axios from "axios";
import PontosDeEntrega from "./pontosDeEntrega.vue";
import { showError, urlApi } from "@/global";
export default {
    components: { PontosDeEntrega },
    name: "editar-endereco",
    props: {
        toggle: Function,
        isAdd: Boolean,
        active: Boolean,
        clienteDoPonto: String,
        iEnd: Number,
        ende: Object,
        segmentoCliente: Array,
        clientes: Array
    },
    data() {
        return {
            isEnderecoPadraoEditado: true,
            selecTipoClienteEntrega: ["PF", "PJ"],
            selecSegmentoCliente: [
                "Licitação - Secretarias e Prefeituras",
                "Licitação - Hospitais",
                "Cotação - Hospitais",
                "Concessionárias",
                "Filantrópico",
                "OSS",
                "Rede Verticalizada",
                "Rede não Verticalizada",
                "Hospital Independente",
                "Associações / ONG",
                "Consultórios / Clínicas/Ambulatórios",
                "Home Care",
                "Operadora de Saúde",
                "Distribuidor / Operador Logístico",
                "Residencial para Idosos - RID/ ILPI",
                "Unidade de Transição Hospitalar - UTH",
                "Unidade de Cuidados Prolongados - UCP",
                "Farmácias / Drogarias",
                "PDV Cirúrgicas",
                "PDV Saudável / Suplementos",
                "Empório",
                "Compra em Venda Balcão",
                "E-commerce",
                "NAV",
            ],
            entrega: {},
        };
    },
    watch: {
        active: {
            handler(novoPonto) {
                if (novoPonto) {
                    if (typeof this.ende.idCliente !== 'string') {
                        if (this.ende.idCliente.objectId == this.clienteDoPonto && novoPonto) {
                            this.entrega = Object.assign({}, this.ende)
                        }
                    } else {
                        if (this.ende.idCliente == this.clienteDoPonto && novoPonto) {
                            this.entrega = Object.assign({}, this.ende)
                        }
                    }
                } else {
                    this.entrega = {}
                }
            },
            immediate: true
        },
    },
    methods: {
        alterarPontoEntrega() {
            this.entrega.idCliente = this.clienteDoPonto
            axios.post(`${urlApi}/alterar-ponto-de-entrega`, this.entrega).then(res => {
                const objectIdCliente = {}
                objectIdCliente.objectId = res.data.result.idCliente.objectId
                res.data.result.idCliente = objectIdCliente
                /* this.enviarPontoEntrega(res.data.result) */
                this.atDadosPonto(res.data.result)
                this.toggle()
            }).catch(showError)

        },
        enviarPontoEntrega(ponto) {
            this.$emit("receberPontoEntrega", ponto);
        },
        salvarNovoPontoDeEntrega() {
            this.entrega.idCliente = this.clienteDoPonto
            axios.post(`${urlApi}/cadastrar-ponto-de-entrega`, this.entrega).then(res => {
                const objectIdCliente = {}
                objectIdCliente.objectId = res.data.result.idCliente.objectId
                res.data.result.idCliente = objectIdCliente
                this.enviarPontoEntrega(res.data.result)
                this.$toasted.global.defaultSuccess({
                    msg: "Ponto de entrega cadastrado com sucesso!",
                });
                this.toggle()
            }).catch(showError)
        },
        setarDataPontoEntrega(set) {
            let setEntrega = {}
            setEntrega = Object.assign({}, set[0])

            this.entrega.objectId = setEntrega.objectId
            this.entrega.codClienteEntrega = setEntrega.codCliente
            this.entrega.razaoSocialEntrega = setEntrega.razaoSocial
            this.entrega.cnpjEntrega = setEntrega.cnpjCpf

            /* OBS NÃO TEM entrega.dados em segmento cliente e tipo pessoa */
            this.entrega.segmentoClienteEntrega = setEntrega.segmentoCliente
            if (setEntrega.pessoaFisica == "NÃO" || setEntrega.pessoaFisica == "PJ") {
                this.entrega.tipoClienteEntrega = "PJ"
            } else {
                this.entrega.tipoClienteEntrega = "PF"
            }
            this.entrega.enderecoClienteEntrega = setEntrega.logradouro
            this.entrega.cidadeEntrega = setEntrega.cidade
            this.entrega.cepEntrega = setEntrega.cep
            this.entrega.ufEntrega = setEntrega.uf
        },
        atDadosPonto(dados) {
            this.$emit("atDadosPonto", dados);
        },
    },
};
</script>
  
<style>
.height500 {
    height: 500px
}

.height250 {
    height: 250px
}
</style>