<template>
  <div>
    <v-container fluid class="c-content-produtos-clientes">
      <v-row align="start" class="ma-0 pa-0">
        <v-col cols="12" lg="12" md="12" sm="12">
          <produtos></produtos>
        </v-col>
        <v-col cols="12" lg="12" md="12" sm="12" class="c-col-cliente">
            <tabelaClientes></tabelaClientes>
          </v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
import tabelaClientes from "./tabelaClientes";
import produtos from "./produtos";
export default {
  name: "sellOut",
  props: {
    dadosUser: Object,
  },
  components: {
    tabelaClientes,
    produtos,
  },
  data: function () {
    return {};
  },

  watch: {},
  // definindo estilos
  computed: {},
  methods: {},
  created() { },
};
</script>

<style>
.u-hide {
  display: none !important;
}
</style>
