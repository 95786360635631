<!-- eslint-disable -->
<template>
  <v-slide-y-reverse-transition appear>
    <v-data-table v-model="clientesSelecionados" item-key="objectId" :headers="headers" :items="clientes"
      sort-by="nomeFantasia" :search="search" class="quinta c-table-cliente rounded-lg rounded-b-0"
      :loading="custoLoading" dark>
      <template v-slot:top>

        <v-toolbar flat color="quinta" class="rounded-lg rounded-b-0">
          <v-toolbar-title class="mr-2">Empresa:</v-toolbar-title>

          <v-text-field outlined v-model="search" append-icon="mdi-magnify" label="Buscar cliente" single-line
            hide-details></v-text-field>

          <v-col class="ml-10" cols="2" md="2" sm="4">
            <v-switch v-model="isExcluir" color="#CA4745" class="mb-n4"></v-switch>
            <h6>EXCLUIR</h6>
          </v-col>
          <!-- BTN PARA ABRIR O PAINEL DE HISTÓRICO -->
          <v-col cols="12" md="2" sm="12">
            <v-dialog v-model="openHistoricoSell" fullscreen>
              <v-card dark color="quinta">
                <v-card-title class="oitava--text">Histórico de Envio Sell-Out</v-card-title>
                <v-btn color="quinta" small absolute right float fab dark @click="openHistoricoSell = false"
                  class="mt-n13 mr-3">
                  <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-card-text class="ma-0 pa-0">
                  <historicoSellOut :openHistoricoSell="openHistoricoSell"></historicoSellOut>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>

          <!--  Dialogo para Adicionar novo cliente ou alterar um existente -->
          <v-dialog v-model="dialogAddVenda" fullscreen>

            <template v-slot:activator="{ on, attrs }">
              <!-- BOTÃO PARA ADD NOVO CLIENTE! -->
              <v-btn color="primeira" fab v-bind="attrs" v-on="on">
                <v-icon large>add</v-icon>
              </v-btn>

              <v-btn @click="openHistoricoSell = true" class="mx-5" fab color="primeira" dark>
                <v-icon>article</v-icon>
              </v-btn>
            </template>
            <v-card dark color="primeira">
              <!-- BARRA TITULO MODAL CLIENTE -->
              <v-card-title class="pa-0">
                <v-sheet color="primeira" elevation="3" width="100%" class="ma-0 pa-3 mb-5">
                  <!-- Titulo do modal de cliente ou Add Novo Cliente -->
                  <span class="text-h5 nona--text font-weight-medium">{{ formTitle }}</span>
                  <!-- <span v-if="formTitle == 'Dados da Empresa'" class="pl-2 text-h6 white--text text-right">
                  {{ editedItem.razaoSocial }}
                  </span> -->

                  <!-- Botão de Fechar do Modal de cliente -->
                  <v-btn color="primeira" small absolute right float fab dark @click="dialogAddVenda = false"
                    class="mr-2 mt-n1">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>

                  <!-- BTN Para salvar novos dados de cliente ou enviar o SELL OUT -->
                  <v-speed-dial v-model="openSpeedDial" fixed bottom right direction="top" open-on-hover
                    transition="slide-y-reverse-transition" class="mt-16 mr-4 pb-6">
                    <template v-slot:activator>
                      <v-btn :loading="envidoSellOut" :class="{ 'u-margin-top': editedIndex === -1 }"
                        v-model="openSpeedDial" color="primeira" dark fab elevation="24">
                        <v-icon large v-if="openSpeedDial"> mdi-close </v-icon>
                        <v-icon large v-else>cloud_sync</v-icon>
                      </v-btn>
                    </template>
                    <div v-if="editedIndex > -1" class="text-center">
                      <v-btn fab @click="enviarSellOutDeVenda()" color="terceira" class="mx-5"><v-icon large>cloud_upload</v-icon>
                      </v-btn>
                      <span class="c-btn-enviar_sell_out_text">Enviar Sell-Out</span>
                    </div>
                    <div class="text-center">
                      <v-btn fab @click="save()" color="terceira" class="mx-5" small><v-icon>save</v-icon>
                      </v-btn>
                      <span class="c-btn-enviar_sell_out_text">Salvar Edição</span>
                    </div>
                  </v-speed-dial>
                </v-sheet>
              </v-card-title>

              <!-- CORPO MODAL CLIENTE -->
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="2" md="2" class="mr-auto">
                      <v-select v-model="editedItem.sold" outlined color="nona" background-color="primeira"
                        :items="setEmpresa" label="Selecione a empresa" class="c-select-empresa" return-object>

                        <template v-slot:selection="{ item }">
                          <span> {{ item.nomeGenerico }}</span>
                        </template>

                        <template v-slot:item="{ item }">
                          <span class="primeira--text font-weight-bold">
                            {{ item.nomeGenerico }}
                          </span>
                        </template>

                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="mr-auto">
                      <v-text-field outlined v-model="editedItem.codCliente" label="Cód. Cliente"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="mb-0">
                      <v-text-field v-model="editedItem.nomeFantasia" label="Nome Fantasia"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="mb-0">
                      <v-text-field :key="editedIndex" v-maska="['###.###.###-##', '##.###.###/####-##']"
                        v-model="editedItem.cnpjCpf" type="text" label="CNPJ/CPF"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="ml-auto mt-n11">
                      <v-select dark outlined item-color="quinta" v-model="editedItem.segmentoCliente"
                        :items="segmentoCliente" label="Segmento Cliente">
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="mt-n11">
                      <v-select dark outlined v-model="editedItem.pessoaFisica" :items="selecPessoaFisica"
                        label="Pessoa Física" class="c-select__pessoaFisica"></v-select>
                    </v-col>

                    <!-- SEÇÃO ENDEREÇO PADRÃO E CADASTRAR PONTOS DE ENTREGA -->
                    <v-col cols="12" class="mt-n11">

                      <!-- TITULO DA SEÇÃO DADOS PARA ENTREAGA -->
                      <v-col class="pt-7 pb-13">
                        <v-row align="center" align-content="center">
                          <v-divider class="ma-2 linha-endereco" />
                          <h6 class="text-caption text-uppercase">DADOS PARA ENTREGA</h6>
                          <v-divider v-if="quantPontosDeEntrega" class="mx-4" vertical></v-divider>
                          <v-chip v-if="quantPontosDeEntrega" dense color="transparent" class="elevation-4">
                            <h6 v-if="quantPontosDeEntrega > 1" class="text-caption text-uppercase">VISUALIZAR OS <span
                                class="rounded-circle quarta c-quant-ponto">{{
                                  quantPontosDeEntrega }}</span> PONTOS DE ENTREGA PARA ESTE CLIENTE
                            </h6>
                            <h6 v-if="quantPontosDeEntrega === 1" class="text-caption text-uppercase">VISUALIZAR <span
                                class="rounded-circle quarta py-1 px-2">{{
                                  quantPontosDeEntrega }}</span> PONTO DE ENTREGA PARA ESTE CLIENTE
                            </h6>
                            <v-switch dense v-model="toViewPontosDeEntrega" color="green" hide-details
                              class="pl-1 py-0 ma-0">
                            </v-switch>
                          </v-chip>
                          <v-divider class="ma-2 linha-endereco" />
                        </v-row>
                      </v-col>
                      <!-- DADOS PARA SEÇÃO CARDS DE ENDEREÇO PADRÃO E PONTOS DE ENTREGA! -->
                      <v-item-group v-model="qualPonto">
                        <v-container>
                          <v-row>
                            <v-col v-for="(ende, iEnd) in pontoEndereco" :key="iEnd" cols="12" md="4" :class="{
                              'u-hide-ponto-entrega': !toViewPontosDeEntrega && !ende.entregaPadrao && iEnd !== 0 && pontoEndereco.length - 1 !== iEnd
                            }">
                              <!-- PONTO DE ENTREGA -->
                              <v-item :key="iEnd" v-slot="{ active, toggle }">

                                <div>
                                  <!-- PRIMEIRO CARD DADOS DE ENDEREÇO DO CLIENTE PADRÃO-->
                                  <v-card v-if="iEnd == 0" :key="iEnd" dark rounded="lg"
                                    :color="isAddPonto(iEnd, ende.entregaPadrao)"
                                    class="align-center c-card-ponto-entrega mb-4">
                                    <!-- Nome do Ponto -->
                                    <v-card-title
                                      class="c-card-item text-uppercase font-weight-black text-subtitle-1 mb-0 pb-0">
                                      <!-- <v-icon small>ads_click</v-icon> -->
                                      <v-text-field dense readonly outlined background-color="transparent"
                                        :value="ende.nomeEndereco" hide-details class="c-inf-nome mt-4">
                                      </v-text-field>
                                      <!-- <span>PONTO DE ENTREGA:</span> {{ ende.nomeEndereco }} -->
                                    </v-card-title>
                                    <!-- Informações do Ponto -->
                                    <v-card-text class="pt-2 my-0">
                                      <v-divider class="mb-3" />
                                      <!-- Informações do endereço Padrão -->
                                      <div class="c-informacoes">
                                        <v-text-field dense background-color="transparent" v-model="editedItem.logradouro"
                                          hide-details class="c-inf u-font-13 pa-0 mb-0 mt-6" label="LOGRADOURO:">
                                        </v-text-field>
                                        <v-text-field dense background-color="transparent" v-model="editedItem.numero"
                                          hide-details class="c-inf u-font-13 pa-0 mb-0 mt-6" label="NÚMERO:">
                                        </v-text-field>
                                        <v-text-field dense background-color="transparent"
                                          v-model="editedItem.complemento" hide-details
                                          class="c-inf u-font-13 pa-0 mb-0 mt-6" label="COMPLEMENTO:">
                                        </v-text-field>
                                        <v-row class="my-0 py-0">
                                          <v-col cols="6" class="py-0">
                                            <v-text-field dense background-color="transparent" v-model="editedItem.bairro"
                                              hide-details class="c-inf u-font-13 pa-0 mb-0 mt-6" label="BAIRRO:">
                                            </v-text-field>
                                          </v-col>
                                          <v-col cols="6" class="py-0">
                                            <v-text-field dense background-color="transparent" v-model="editedItem.cidade"
                                              hide-details class="c-inf u-font-13 pa-0 mb-0 mt-6" label="CIDADE:">
                                            </v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row class="my-0 pa-0">
                                          <v-col cols="6">
                                            <v-select hide-details v-model="editedItem.uf" dense :items="siglaBr"
                                              label="UF:" outlined class="c-inf">
                                            </v-select>
                                          </v-col>
                                          <v-col cols="6" class="py-0">
                                            <v-text-field :key="iEnd" v-maska="'##.###-###'" dense
                                              background-color="transparent" v-model="editedItem.cep" hide-details
                                              class="c-inf u-font-13 pa-0 mb-0 mt-6" label="CEP:">
                                            </v-text-field>
                                          </v-col>
                                        </v-row>
                                        <!-- SWITCH PARA DEFINIR O ENDEREÇO PADÃO DO CLIENTE COMO ENDEREÇO DE ENTREGA -->
                                        <v-row v-if="!(pontoEndereco.length <= 2)" align="center" align-content="center"
                                          class="elevation-4 rounded pa-4 ma-0 mt-4">
                                          <v-col cols="8" class="py-0 ma-0">
                                            <p v-if="!ende.entregaPadrao" class="c-definir-entrega-padrao pa-0 ma-0">
                                              Utilizar este endereço como
                                              entrega ?</p>
                                            <p v-else class="c-definir-entrega-padrao pa-0 ma-0">Endereço definido como
                                              ponto de entrega
                                              <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-icon small v-bind="attrs" v-on="on"
                                                    class="mt-n1">help_outline</v-icon>
                                                </template>
                                                <span class="text-caption">VOCÊ PODE CRIAR OU SELECIONAR OUTRO PONTO DE
                                                  ENTREGA NO CARD AO LADO</span>
                                              </v-tooltip>
                                            </p>
                                          </v-col>
                                          <v-col cols="4" class="py-0 ma-0">
                                            <v-chip dense color="transparent" class="elevation-4">
                                              <v-switch :disabled="ende.entregaPadrao" dense v-model="ende.entregaPadrao"
                                                @change="enderecoPadraoParaEntrega()" color="green" hide-details
                                                class="pl-1 py-0 ma-0">
                                              </v-switch>
                                            </v-chip>
                                          </v-col>
                                        </v-row>
                                      </div>

                                      <!-- Marcação caso o ponto seja entrega padrão -->
                                      <v-scroll-y-transition>
                                        <div width="100%" class="c-check ma-0 pa-0 d-flex justify-center d-absolute"
                                          v-if="ende.entregaPadrao">
                                          <v-chip color="quarta" class="c-check-entrega">Endereço de entrega
                                            <v-icon color="green" right>
                                              check_circle
                                            </v-icon>
                                          </v-chip>
                                        </div>
                                      </v-scroll-y-transition>
                                    </v-card-text>
                                  </v-card>

                                  <!-- CARDS DE PONTOS DE ENTREGA! -->
                                  <v-card v-else :key="iEnd" dark rounded="lg"
                                    :color="isAddPonto(iEnd, ende.entregaPadrao)"
                                    class="align-center c-card-ponto-entrega mb-4" @click="toggle" :class="{
                                      'c-card-add': pontoEndereco.length - 1 === iEnd
                                    }">
                                    <!-- Nome do Ponto -->
                                    <v-card-title v-if="pontoEndereco.length - 1 !== iEnd"
                                      class="c-card-item text-uppercase font-weight-black text-subtitle-1 mb-0 pb-0">
                                      <!-- <v-icon small>ads_click</v-icon> -->
                                      <v-text-field dense readonly outlined background-color="transparent"
                                        :value="ende.nomeEndereco" hide-details label="NOME DO PONTO DE ENTREGA"
                                        class="c-inf-nome mt-4">
                                      </v-text-field>
                                      <!-- <span>PONTO DE ENTREGA:</span> {{ ende.nomeEndereco }} -->
                                    </v-card-title>
                                    <!-- Informações do Ponto -->
                                    <v-card-text class="pt-2 my-0">
                                      <v-divider class="mb-3" v-if="pontoEndereco.length - 1 !== iEnd" />
                                      <!-- Icon do card para add novo ponto -->
                                      <v-icon v-if="pontoEndereco.length - 1 == iEnd" class="c-icon-add-ponto" x-large>add
                                      </v-icon>
                                      <!-- Informações do endereço do ponto de entrega -->
                                      <div v-if="pontoEndereco.length - 1 != iEnd" class="c-informacoes pb-5">
                                        <v-text-field disabled dense background-color="transparent"
                                          :value="ende.razaoSocialEntrega" hide-details
                                          class="c-inf u-font-15 pa-0 mb-0 mt-6"
                                          label="RAZÃO SOCIAL DO PONTO DE ENTREGA:">
                                        </v-text-field>
                                        <v-text-field disabled dense background-color="transparent"
                                          :value="ende.enderecoClienteEntrega" hide-details
                                          class="c-inf u-font-15 pa-0 mb-0 mt-6" label="ENDEREÇO:">
                                        </v-text-field>
                                        <v-row>
                                          <v-col cols="8" class="">
                                            <v-text-field disabled label="CIDADE:" dense background-color="transparent"
                                              :value="ende.cidadeEntrega" hide-details
                                              class="c-inf u-font-15 pa-0 ma-0 mt-6">
                                            </v-text-field>
                                          </v-col>
                                          <v-col cols="4">
                                            <v-text-field disabled label="UF:" dense background-color="transparent"
                                              :value="ende.ufEntrega" hide-details class="c-inf u-font-15 pa-0 ma-0 mt-6">
                                            </v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-text-field disabled label="CEP:" dense background-color="transparent"
                                          :value="ende.cepEntrega" hide-details class="c-inf u-font-15 pa-0 ma-0 mt-6">
                                        </v-text-field>
                                        <v-text-field disabled :key="iEnd"
                                          v-maska="['###.###.###-##', '##.###.###/####-##']" label="CNPJ/CPF:" dense
                                          background-color="transparent" :value="ende.cnpjEntrega" hide-details
                                          class="c-inf u-font-15 pa-0 ma-0 mt-6">
                                        </v-text-field>
                                        <v-text-field disabled label="SEGMENTO:" dense background-color="transparent"
                                          :value="ende.segmentoClienteEntrega" hide-details
                                          class="c-inf u-font-15 pa-0 ma-0 mt-6">
                                        </v-text-field>
                                        <v-text-field disabled prefix="CÓD.:" dense background-color="transparent"
                                          :value="ende.codClienteEntrega" hide-details class="c-inf u-font-15 pa-0 mt-2">
                                        </v-text-field>
                                      </div>
                                      <!-- Marcação caso o ponto seja entrega padrão -->
                                      <v-scroll-y-transition>
                                        <div width="100%" class="c-check ma-0 pa-0 d-flex justify-center d-absolute"
                                          v-if="ende.entregaPadrao">
                                          <v-chip color="quarta" class="c-check-entrega">Endereço de entrega<v-icon
                                              color="green" right>
                                              check_circle
                                            </v-icon></v-chip>
                                        </div>
                                      </v-scroll-y-transition>
                                      <!-- Dialogo para os dados do ponto -->
                                      <v-dialog v-model="active" scrollable persistent max-width="1050px">
                                        <EditarEndereco :active="active" :clienteDoPonto="editedItem.objectId"
                                          :ende="ende" :toggle="toggle" :iEnd="iEnd" :clientes="clientes"
                                          :segmentoCliente="segmentoCliente" :isAdd="pontoEndereco.length - 1 == iEnd"
                                          @receberPontoEntrega="addNovoPontoEntrega($event)"
                                          @atDadosPonto="atDadosPontoEntrega($event)">
                                        </EditarEndereco>
                                      </v-dialog>
                                    </v-card-text>
                                  </v-card>
                                </div>
                              </v-item>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-item-group>
                    </v-col>

                    <!-- AQUI ESTAVAM DADOS ANTIGOS DA 1VERSÃO  -->

                    <!-- DADOS PARA CADASTRAR OS ITENS DOS PRODUTOS DA VENDA -->
                    <!-- TITULO PARA SEÇÃO PARA CADASTRAR NOVOS PRODUTOS DA VENDA  -->
                    <v-col v-if="editedIndex > -1" class="pt-7 pb-0">
                      <v-row align="center" align-content="center">
                        <v-divider class="ma-2 linha-endereco" />
                        <h6 class="text-caption text-uppercase">DADOS FISCAIS</h6>
                        <v-divider class="mx-4" vertical></v-divider>
                        <h6 class="text-caption text-uppercase">E TIPO DE OPERAÇÃO</h6>
                        <v-divider class="ma-2 linha-endereco" />
                      </v-row>
                    </v-col>
                    <!-- CESSÃO COMPONENTE DA TABELA DE VENDAS -->
                    <v-col v-if="editedIndex > -1" cols="12" sm="12" md="12">
                      <vendas :zerarDados="zerarDados" @sendDadosVenda="getDataVendas($event)"
                        @dadosZerados="isDadosZerados($event)"></vendas>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <!-- Ações para Salvar ou cancelar mudanças -->
              <!--             <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="segunda" @click="close">Cancelar</v-btn>
              <v-btn color="quinta" @click="save">Salvar</v-btn>
              </v-card-actions> -->
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card dark color="quinta">
              <v-card-title class="text-h5">Tem certeza de que deseja excluir este cliente?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="segunda" @click="closeDelete">Cancel</v-btn>
                <v-btn color="quinta" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>

      </template>
      <template #item.nomeFantasia="{ item }">{{ item.nomeFantasia }}</template>

      <template #item.codCliente="{ item }">
        <v-text-field dense class="u-hide" readonly type="text" :value="item.codCliente"></v-text-field>
      </template>

      <template #item.pessoaFisica="{ item }">
        <v-text-field dense class="u-hide" readonly type="text" :value="item.pessoaFisica"></v-text-field>
      </template>

      <template #item.sold="{ item }">
        <v-text-field dense class="u-hide" readonly type="number" :value="item.sold"></v-text-field>
      </template>

      <template #item.segmentoCliente="{ item }">
        <v-text-field dense class="u-hide" readonly type="text" :value="item.segmentoCliente"></v-text-field>
      </template>

      <template #item.razaoSocial="{ item }">
        <v-text-field dense class="u-hide" readonly type="text" :value="item.razaoSocial"></v-text-field>
      </template>

      <template #item.cnpjCpf="{ item }">
        <v-text-field dense class="u-hide" readonly type="text" :value="item.cnpjCpf"></v-text-field>
      </template>

      <template #item.logradouro="{ item }">
        <v-text-field dense class="u-hide" readonly type="text" :value="item.logradouro"></v-text-field>
      </template>

      <template #item.numero="{ item }">
        <v-text-field dense class="u-hide" readonly type="text" :value="item.numero"></v-text-field>
      </template>

      <template #item.complemento="{ item }">
        <v-text-field dense class="u-hide" readonly type="text" :value="item.complemento"></v-text-field>
      </template>

      <template #item.bairro="{ item }">
        <v-text-field dense class="u-hide" readonly type="text" :value="item.bairro"></v-text-field>
      </template>

      <template #item.cidade="{ item }">
        <v-text-field dense class="u-hide" readonly type="text" :value="item.cidade"></v-text-field>
      </template>

      <template #item.cep="{ item }">
        <v-text-field dense class="u-hide" readonly type="text" :value="item.cep"></v-text-field>
      </template>

      <template #item.uf="{ item }">
        <v-text-field dense class="u-hide" readonly type="text" :value="item.uf"></v-text-field>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn color="quinta" @click="editItem(item)" small>
          VISUALIZAR EMPRESA <v-icon right>flip_camera_android</v-icon>
        </v-btn>
        <v-btn v-if="isExcluir" color="quinta" @click="deleteItem(item)" x-small fab class="ml-4">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:no-data>
        <v-btn color="primeira" @click="initialize">Carregar dados</v-btn>
      </template>
    </v-data-table>
  </v-slide-y-reverse-transition>
</template>

<script>
import axios from "axios";
import { urlApi, showError, cofingK, apiMagically } from "@/global";
import EditarEndereco from "./editarEndereco.vue";
import Vendas from "./vendas.vue";
import historicoSellOut from "@/components/historico/historicoSellOut.vue";
export default {
  name: "tabelaClientes",
  components: { Vendas, historicoSellOut, EditarEndereco },
  data: () => ({
    siglaBr: [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO"
    ],
    siglaSelecionada: "RJ",
    toViewPontosDeEntrega: false,
    qualPonto: [],
    pontoEndereco: [],
    dialogEditarEndereco: false,
    zerarDados: false,
    envidoSellOut: false,
    setEmpresa: [
      {
        sold: 5040874,
        key: "80177262-9E41-467F-9E5A-96521191DAB4",
        grupo: "Nutrimix",
        nome: "Nutrimix Comercial Ltda",
        nomeGenerico: "Nutrimix",
      },
      {
        sold: 6029808,
        key: "1E9E8E68-8952-45E0-AD01-448C4BBB4420",
        grupo: "Nutrimix",
        nome: "Nutriplan Comercial Ltda",
        nomeGenerico: "Nutriplan",
      },
      {
        sold: 7249373,
        key: "0E40E860-1951-4C5D-814E-F6E360763A06",
        grupo: "Nutrimix",
        nome: "Nutrimix Brasília Comercial Ltda",
        nomeGenerico: "Nutrimix Brasília",
      }
    ],
    openHistoricoSell: false,
    openSpeedDial: false,
    selecPessoaFisica: ["SIM", "NÃO"],
    venda: {
      tipoDeDocumento: ["CM", "CO", "DT", "TR", "VE", "CL"],
    },
    isNovosDadosEntrega: false,
    isExcluir: false,
    clientesSelecionados: [],
    custoLoading: false,
    search: "",
    dialogAddVenda: false,
    dialogDelete: false,
    headers: [
      {
        text: "Nome Fantasia",
        align: "start",
        sortable: true,
        value: "nomeFantasia",
        filterable: true,
      },
      {
        align: "start",
        value: "segmentoCliente",
        sortable: false,
        filterable: true,
      },
      { value: "sold", sortable: false },
      { value: "codCliente", sortable: false, filterable: true },
      { value: "pessoaFisica", sortable: false },
      { value: "razaoSocial", sortable: false, filterable: true },
      { value: "cnpjCpf", sortable: false, filterable: true },
      { value: "logradouro", sortable: false },
      { value: "numero", sortable: false },
      { value: "complemento", sortable: false },
      { value: "bairro", sortable: false },
      { value: "cidade", sortable: false, filterable: true },
      { value: "cep", sortable: false },
      { value: "uf", sortable: false },
      {
        align: "center",
        text: "Editar/Envir Sell-out",
        value: "actions",
        sortable: false,
      },
    ],
    clientes: [],
    dadosSellOutDeVenda: {},
    getVendas: {
      dataVenda: "",
      notaFiscal: "",
      periodo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      produtos: [],
      tipoDeDocumento: "",
    },
    editedIndex: -1,
    segmentoCliente: [
      "Licitação - Secretarias e Prefeituras",
      "Licitação - Hospitais",
      "Cotação - Hospitais",
      "Concessionárias",
      "Filantrópico",
      "OSS",
      "Rede Verticalizada",
      "Rede não Verticalizada",
      "Hospital Independente",
      "Associações / ONG",
      "Consultórios / Clínicas/Ambulatórios",
      "Home Care",
      "Operadora de Saúde",
      "Distribuidor / Operador Logístico",
      "Residencial para Idosos - RID/ ILPI",
      "Unidade de Transição Hospitalar - UTH",
      "Unidade de Cuidados Prolongados - UCP",
      "Farmácias / Drogarias",
      "PDV Cirúrgicas",
      "PDV Saudável / Suplementos",
      "Empório",
      "Compra em Venda Balcão",
      "E-commerce",
      "NAV",
    ],
    editedItem: {
      sold: {},
      vendas: {},
      segmentoCliente: "",
      nomeFantasia: "",
      pessoaFisica: "",
      razaoSocial: "",
      cnpjCpf: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      cep: "",
      uf: "",
    },
    defaultItem: {
      sold: {},
      vendas: {},
      segmentoCliente: "",
      nomeFantasia: "",
      pessoaFisica: "",
      razaoSocial: "",
      cnpjCpf: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      cep: "",
      uf: "",
    },
  }),
  computed: {
    quantPontosDeEntrega() {
      const q = this.pontoEndereco.length
      return q > 2 ? q - 2 : false;
    },
    isAddPonto() {
      return (id, isEntregaPadrao) => {

        if (isEntregaPadrao) return 'primary';
        if (id == 0) return 'sexta';
        if (this.pontoEndereco.length - 1 == id) return 'transparent';
        if (!isEntregaPadrao) return 'oitava';

      };
    },
    valueTipoPessoa() {
      return (pessoaFisica, sim) => {
        return pessoaFisica === sim ? "PF" : "PJ";
      };
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nova Empresa" : "Dados da Empresa";
    },
  },
  watch: {
    dialogAddVenda(val) {
      if (!val) {
        this.close();
        this.qualPonto = [];
        this.pontoEndereco = [];
        this.clientesSelecionados = [];
        this.dadosSellOutDeVenda = {};
        this.getVendas = {
          dataVenda: "",
          notaFiscal: "",
          periodo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          produtos: [],
          tipoDeDocumento: "",
        }
      } else {
        if (this.editedIndex === -1) {
          this.pontoEndereco[0] = {
            objectId: '',
            nomeEndereco: 'ENDEREÇO DO CLIENTE',
            entregaPadrao: true
          }
        }
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    /* async getDataEHoraExatas() {
      try {
        const { data: { datetime } } = await axios.get('https://worldtimeapi.org/api/ip');
        const dataHoraCorretas = new Date(datetime);
        return dataHoraCorretas;
      } catch (e) {
        showError(`Erro ao obter a data e hora corretas${e}`)
      }
    }, */

    async getDataEHoraExatas() {
      try {
        const response = await axios.get('https://worldtimeapi.org/api/timezone/America/Sao_Paulo');
        const { datetime } = response.data;
        return new Date(datetime);
      } catch (e) {
        console.log(`Erro ao obter a data e hora da API. Usando hora do sistema ajustada para o Brasil. Erro: ${e}`);

        // Obtenha a data/hora do sistema e ajuste para o fuso horário do Brasil
        const dataHoraCorretas = new Date();
        const offset = -3 * 60; // UTC-3 (BRT)
        dataHoraCorretas.setMinutes(dataHoraCorretas.getMinutes() + dataHoraCorretas.getTimezoneOffset() + offset);
        return dataHoraCorretas;
      }
    },
    enderecoPadraoParaEntrega() {
      if (this.pontoEndereco[0].entregaPadrao) {
        this.pontoEndereco.forEach((pontos, idx) => {
          if (idx !== 0) pontos.entregaPadrao = false
        })
        this.toViewPontosDeEntrega = false
        this.definirPontoDeEntregaPadrao(this.pontoEndereco[0])
        return
      }
    },
    definirPontoDeEntregaPadrao(entregaDefinidaComoPadrao) {
      if (typeof entregaDefinidaComoPadrao.idCliente !== 'string') {
        entregaDefinidaComoPadrao.idCliente = entregaDefinidaComoPadrao.idCliente.objectId
      }
      axios
        .post(`${urlApi}/definir-ponto-entrega-padrao`, entregaDefinidaComoPadrao).then(() => {
          this.$toasted.global.defaultSuccess({
            msg: "Ponto de entrega atualizado!",
          });
        }).catch(showError);
    },
    atDadosPontoEntrega(ponto) {
      this.pontoEndereco.forEach((pontos, idx) => {
        if (pontos.objectId === ponto.objectId) {
          this.pontoEndereco[idx] = ponto
        } else {
          if (ponto.entregaPadrao) {
            pontos.entregaPadrao = false
          }
        }
      })
      if (ponto.entregaPadrao) {
        this.definirPontoDeEntregaPadrao(ponto)
      }
    },
    addNovoPontoEntrega(ponto) {
      /* this.pontoEndereco.push(ponto) */
      this.pontoEndereco.splice(this.pontoEndereco.length - 1, 0, ponto);
      if (ponto.entregaPadrao) {
        this.pontoEndereco.forEach((pontos) => {
          if (pontos.objectId !== ponto.objectId) {
            pontos.entregaPadrao = false
          }
        })
        this.definirPontoDeEntregaPadrao(ponto)
        this.toViewPontosDeEntrega = true
      }
    },
    isDadosZerados() {
      this.zerarDados = false;
    },
    deletarCliente(idCliente) {
      let deletCliente = {};
      deletCliente.objectId = idCliente;
      axios
        .post(`${urlApi}/deletar-cliente`, deletCliente, {
          headers: cofingK,
        })
        .then(() => {
          this.closeDelete();
          this.$toasted.global.defaultSuccess({
            msg: "Cliente excluido com sucesso!",
          });
        })
        .catch(showError);
    },
    criarCLiente(cliente) {
      const newCliente = Object.assign({}, cliente);
      newCliente.sold = newCliente.sold.sold;
      newCliente.razaoSocial = newCliente.nomeFantasia;
      delete newCliente.vendas;
      axios
        .post(`${urlApi}/criar-cliente`, newCliente, {
          headers: cofingK,
        })
        .then((res) => {
          res.data.result.sold = this.editedItem.sold;
          this.clientes.push(res.data.result);
          this.$toasted.global.defaultSuccess({
            msg: "Cliente Criado com sucesso!",
          });
          this.close();
        })
        .catch(showError);
    },
    async criarHistorico(dadosHistorico) {
      let setEpresaEnvio =  'Empresa sem identificação'
      if(dadosHistorico.sold === 5040874) setEpresaEnvio = 'Nutrimix';
      if(dadosHistorico.sold === 6029808) setEpresaEnvio = 'Nutriplan';
      if(dadosHistorico.sold === 7249373) setEpresaEnvio = 'Nutrimix Brasília';
      /* SOLUÇÃO DE TER A HORA E MINUTOS ATUAIS CONFIADO NO DISPOTIVO DO CLIENTE */
      /* const agora = new Date();
      agora.setMinutes(agora.getMinutes() - agora.getTimezoneOffset()); 
      const dataHoraCorretas = agora.toISOString().slice(0, 16)
      */
      const sendHistoricoSellOut = {
        dataEnvio: await this.getDataEHoraExatas(),
        empresa: setEpresaEnvio,
        cliente: dadosHistorico.clientes[0].razaoSocialFaturado,
        clienteEntrega: dadosHistorico.clientes[0].vendas[0].razaoSocialEntrega,
        periodo: dadosHistorico.periodo,
        dataNf: dadosHistorico.clientes[0].vendas[0].dataVenda,
        cnpj: dadosHistorico.clientes[0].cnpjFaturado,
        cnpjEntrega: dadosHistorico.clientes[0].vendas[0].cnpjEntrega,
        notaFiscal: dadosHistorico.clientes[0].vendas[0].notaFiscal,
        produtos: dadosHistorico.clientes[0].vendas[0].produtos,
        tipoDeDocumento: dadosHistorico.clientes[0].vendas[0].tipoDeDocumento,
        logs: {
          email: apiMagically.logEmailUser,
          linkApi: apiMagically.urlsApi.urlImportacao
        }
      };
      axios
        .post(`${urlApi}/criar-historico-sellout`, sendHistoricoSellOut, {
          headers: cofingK,
        })
        .then(() => {
          this.envidoSellOut = false;
          this.$toasted.global.defaultSuccess();
        })
        .catch(() => this.envidoSellOut = false);
    },
    getDataVendas(dados) {
      this.getVendas = Object.assign({}, dados);
    },
    enviarSellOutDeVenda() {
      this.envidoSellOut = true;
      let pontoEntregaDefinido = this.pontoEndereco.filter(ponto => ponto.entregaPadrao)
      if (pontoEntregaDefinido[0].objectId === this.editedItem.objectId) {
        pontoEntregaDefinido[0] = {
          cepEntrega: this.editedItem.cep,
          cidadeEntrega: this.editedItem.cidade,
          cnpjEntrega: this.editedItem.cnpjCpf,
          codClienteEntrega: this.editedItem.codCliente,
          enderecoClienteEntrega: this.editedItem.logradouro,
          entregaPadrao: true,
          razaoSocialEntrega: this.editedItem.razaoSocial,
          segmentoClienteEntrega: this.editedItem.segmentoCliente,
          tipoClienteEntrega: this.editedItem.pessoaFisica === "NÃO" ? "PJ" : "PF",
          ufEntrega: this.editedItem.uf,
        };

      }
      let infoProduto = [];
      let infoCliente = [];
      let infoVenda = [];
      let dataSellAout = {};

      /* Dados Distribuidor */
      this.dadosSellOutDeVenda.sold = this.editedItem.sold.sold;
      this.dadosSellOutDeVenda.secrectKeyDistribuidora =
        this.editedItem.sold.key;
      this.dadosSellOutDeVenda.periodo = this.getVendas.periodo;

      /* Dados Clientes */
      infoCliente[0] = {
        razaoSocialFaturado: this.editedItem.razaoSocial,
        cnpjFaturado: this.editedItem.cnpjCpf,
        codCLienteFaturado: this.editedItem.codCliente,
        enderecoClienteFaturado: `${this.editedItem.logradouro} - ${this.editedItem.bairro} - ${this.editedItem.numero} - ${this.editedItem.complemento}`,
        cepFaturado: this.editedItem.cep,
        cidadeFaturado: this.editedItem.cidade,
        ufFaturado: this.editedItem.uf,
        tipoClienteFaturado:
          this.editedItem.pessoaFisica === "NÃO" ? "PJ" : "PF",
        segmentoClienteFaturado: this.editedItem.segmentoCliente,
        vendas: [],
      };

      /* Dados Venda */
      infoVenda[0] = {
        razaoSocialEntrega: pontoEntregaDefinido[0].razaoSocialEntrega,
        cnpjEntrega: pontoEntregaDefinido[0].cnpjEntrega,
        codClienteEntrega: pontoEntregaDefinido[0].codClienteEntrega,
        enderecoClienteEntrega: pontoEntregaDefinido[0].enderecoClienteEntrega,
        cepEntrega: pontoEntregaDefinido[0].cepEntrega,
        cidadeEntrega: pontoEntregaDefinido[0].cidadeEntrega,
        ufEntrega: pontoEntregaDefinido[0].ufEntrega,
        tipoClienteEntrega: pontoEntregaDefinido[0].tipoClienteEntrega,
        tipoDeDocumento: this.getVendas.tipoDeDocumento,
        segmentoClienteEntrega: pontoEntregaDefinido[0].segmentoClienteEntrega,
        notaFiscal: this.getVendas.notaFiscal,
        dataVenda: this.getVendas.dataVenda,
        produtos: [],
      };

      /* Dados Produtos da Venda */
      this.getVendas.produtos.filter((e) => {
        infoProduto.push({
          codProdutoNestle_CodEAN: e.codigo,
          nomeProduto: e.material,
          unidade: e.unidade,
          // novo metodo para seleção global, por padrão será unidade. Possibilidade de edição global.
          unidadeMedida: Object.values(e.unidadeMedida)[0],
          fator: e.statusUnidadeMedida ? 1 : e.qtdPorCx,
        });
      });

      /* Nivelamento do Objeto */
      this.dadosSellOutDeVenda.clientes = [];
      this.dadosSellOutDeVenda.clientes[0] = infoCliente[0];
      this.dadosSellOutDeVenda.clientes[0].vendas[0] = infoVenda[0];
      this.dadosSellOutDeVenda.clientes[0].vendas[0].produtos = infoProduto;

      /* let todoEnvio = {...this.dadosSellOutDeVenda} */

      dataSellAout = JSON.stringify(this.dadosSellOutDeVenda);


      /* axios.post('https://apinestleselloutintegracaodev.magically.com.br/api/Importacao',{ ...todoEnvio } , { 
          transformRequest: [(data, headers) => {

            delete headers.common["X-Parse-Session-Token"]
            headers.post = {
              "x-api-version": "1.1",
              "Content-Type": "application/json",
            }
            console.log('headers >>>', headers)
            console.log('data >>>', data)
            return  JSON.stringify(data)
          }]
        }) */

      axios
        .post(`${apiMagically.urlsApi.urlImportacao}`, dataSellAout, {
          headers: {
            "x-api-version": 1.1,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.envidoSellOut = false;
          const respostaApiMagical = `${res.data.message} Status ${res.data.status} `;
          this.criarHistorico(this.dadosSellOutDeVenda);
          this.$toasted.global.defaultSuccess({ msg: respostaApiMagical });
          this.getVendas.produtos.filter((e) => {
            e.unidade = 0;
          });
          this.zerarDados = true;
        })
        .catch((e) => {
          this.envidoSellOut = false;
          showError(e.response.data.message);
        });
    },
    isAbertoDadosEntrega() {
      if (this.isNovosDadosEntrega) {
        this.isNovosDadosEntrega = false;
        return;
      }
      if (!this.isNovosDadosEntrega) {
        this.isNovosDadosEntrega = true;
        return;
      }
    },
    alterarCliente(clienteAlterado) {
      clienteAlterado.sold = clienteAlterado.sold.sold;
      axios
        .post(`${urlApi}/alterar-dados-cliente`, clienteAlterado, {
          headers: cofingK,
        })
        .then(() => {
          this.$toasted.global.defaultSuccess();
        })
        .catch(showError);
    },
    initialize() {
      this.custoLoading = true;
      axios
        .post(`${urlApi}/listar-clientes`, this.user)
        .then((res) => {
          this.clientes = res.data.result;
          this.custoLoading = false;
        })
        .catch(showError);
    },
    editItem(item) {
      const identificadoSold = typeof item.sold === "number" ? this.setEmpresa.filter((e) => e.sold === item.sold)[0] : item.sold
      const getPontosEntrega = {}
      this.pontoEndereco = []
      /* Utilizo o indexOf para procurar o indice do array, pois o componete me assegura que o item passado para função editItem(), mantem a mesma referência de memoria, por isso funciona tão bem */
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.sold = identificadoSold;
      getPontosEntrega.objectId = this.editedItem.objectId
      axios.post(`${urlApi}/listar-pontos-de-entrega`, getPontosEntrega)
        .then((res) => {
          res.data.result.unshift({
            cepEntrega: this.editedItem.cep,
            cidadeEntrega: this.editedItem.cidade,
            cnpjEntrega: this.editedItem.cnpjCpf,
            codClienteEntrega: this.editedItem.codCliente,
            enderecoClienteEntrega: this.editedItem.logradouro,
            idCliente: { objectId: this.editedItem.objectId },
            razaoSocialEntrega: this.editedItem.razaoSocial,
            segmentoClienteEntrega: this.editedItem.segmentoCliente,
            tipoClienteEntrega: this.editedItem.pessoaFisica,
            ufEntrega: this.editedItem.uf,
            objectId: this.editedItem.objectId,
            nomeEndereco: 'ENDEREÇO PADRÃO DO CLIENTE',
            entregaPadrao: !(res.data.result.find(p => p.entregaPadrao == true)) ? true : false
          })
          this.pontoEndereco = [...res.data.result]
          this.pontoEndereco.push({
            cepEntrega: '',
            cidadeEntrega: '',
            cnpjEntrega: '',
            codClienteEntrega: '',
            enderecoClienteEntrega: '',
            entregaPadrao: false,
            idCliente: { objectId: this.editedItem.objectId },
            nomeEndereco: '',
            objectId: '',
            razaoSocialEntrega: '',
            segmentoClienteEntrega: '',
            tipoClienteEntrega: '',
            ufEntrega: '',
          })
        })
        .catch(showError);
      this.dialogAddVenda = true;
    },
    deleteItem(item) {
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.clientes.splice(this.editedIndex, 1);
      this.deletarCliente(this.editedItem.objectId);
    },
    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.dialogAddVenda = false;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        const clienteEditado = Object.assign(
          this.clientes[this.editedIndex],
          this.editedItem
        );
        this.alterarCliente(clienteEditado);
        this.close();
      } else {
        this.criarCLiente(this.editedItem);
      }
    },
  },
};
</script>

<style>
.c-table-cliente .v-data-footer__select>div {
  display: none;
}

.c-quant-ponto {
  width: 23px !important;
  height: 23px !important;
  font-size: 11px;
  line-height: 23px;
  display: inline-block;
  text-align: center;
}

.u-hide-ponto-entrega {
  display: none;
}

.linha-endereco {
  border-style: dashed;
}

.c-definir-entrega-padrao {
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
}

.c-inf-nome label.v-label {
  padding: 0px !Important;
  font-size: 12px;
  letter-spacing: 0.6px;
  cursor: pointer;
}

.c-inf-nome input {
  padding: 0px !Important;
  cursor: pointer;
}

.c-inf label.v-label {
  padding: 0px !Important;
  font-size: 12px;
  letter-spacing: 0.6px;
  cursor: pointer;
}

.c-inf input {
  padding-top: 0px !Important;
  margin-top: -1px;
  cursor: pointer;
}

.u-font-15 {
  font-size: 15px;
}

.u-font-13 {
  font-size: 13px;
}

.c-inf .v-text-field__prefix {
  font-size: small;
}

.c-card-ponto-entrega {
  min-height: 200px;
}

.c-icon-add-ponto {
  position: absolute !Important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.c-card-add span {
  display: none;
}

.c-card-add {
  box-shadow: none !important;
  height: 100px !important;
  min-height: 100px !important;
}

.c-card-add .v-card__text {
  border: dashed 1px white;
  position: relative !important;
  height: 100px !important;
}

.c-check {
  width: 100%;
  z-index: 10;
  position: absolute;
  left: 0;
  top: -20px;
}

.c-check .c-check-entrega span {
  font-weight: 500 !important;
  text-transform: uppercase;
}

.c-card-ponto-entrega .v-card__text span strong {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}

.c-card-ponto-entrega .v-card__text span {
  font-size: 15px;
  font-weight: 300;
}

.c-select__pessoaFisica .v-select__selection {
  padding-top: 2px;
}

.c-select__pessoaFisica {
  margin-top: 1px !important;
}

.c-data-cliente__sold input::-webkit-outer-spin-button,
.c-data-cliente__sold input::-webkit-inner-spin-button {
  display: none;
  margin: 0px;
}

.c-data-cliente__sold input[type="number"] {
  -moz-appearance: textfield;
}

.c-btn-enviar_sell_out_text {
  width: 50px;
  display: inline-block;
  font-size: x-small;
  text-transform: uppercase;
  line-height: 13px;
  text-align: center;
  word-break: normal;
}

.u-margin-top {
  margin-top: 25px !important;
}

.c-table-cliente .v-sheet {
  height: 100px !important;
  padding-top: 13px;
  margin-top: -20px;
}
</style>
