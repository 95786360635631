<template>
  <div class="c-table-historico">
    <v-data-table :sort-by="ativarFiltragem.item" :sort-desc="ativarFiltragem.desc" :headers="h" single-expand
      :items="dadosFiltrados" :expanded.sync="expanded" item-key="objectId" show-expand
      class="quinta elevation-1 c-table-historico__inventario">

      <!-- BARRA DE PROGRESSO E INPUTS DE DATAS -->
      <template v-slot:top>
        <v-progress-linear absolute top :height="8" :active="loopLoading" :indeterminate="loopLoading" color="#727272"
          class="pt-1"></v-progress-linear>

        <!-- INPUTS DE DATAS -->
        <v-row align="center" align-content="center" class="mx-3 mb-0 py-4">
          <!-- INPUT SELEÇÃO DE DATA INICIO -->
          <v-col cols="7" class="text-center my-0 py-0">
            <input type="datetime-local" v-model="dateFilter.start" label="Data inicial" class="c-input-data"> <v-divider
              class="mx-4" vertical />
            <!-- INPUT SELEÇÃO DE DATA FIM -->
            <input type="datetime-local" v-model="dateFilter.end" label="Data final" class="c-input-data">
          </v-col>
          <v-col cols="5" md="5" class="my-0 py-0 text-center d-flex align-center c-col-botoes">
            <v-chip-group v-model="tipoDeDocumentoParaDownload" column multiple>
              <v-chip filter outlined>
                CSV
              </v-chip>
              <v-chip filter outlined>
                HTML
              </v-chip>
              <v-chip filter outlined>
                PDF
              </v-chip>
            </v-chip-group>
            <v-btn :loading="loadingDownload" :disabled="tipoDeDocumentoParaDownload.length === 0" fab elevation="1"
              width="38" height="38" color="sexta" @click="baixarArquivos">
              <v-icon>file_download</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`expanded-item`]="{ headers, item }">
        <td :colspan="headers.length">
          <div v-for="(produto, idx) in item.produtos" :key="idx">
            <v-chip label color="quarta" class="mt-2"> Produto: {{ produto.nomeProduto }}
              <span v-if="produto.fator == '1'" class="px-1"> | {{ produto.unidades }} {{ produto.unidades > 1 ?
                "Unidades" : "Unidade" }}</span>
              <span v-else class="px-1"> | {{ produto.unidades }} {{ produto.unidades > 1 ? "Caixas" : "Caixa" }}</span>
              <span class="pl-1"> Fator: {{ produto.fator }}</span>
            </v-chip>
            <v-chip label color="quarta" class="mt-2 ml-2"><span class="pl-1"> Dias de estoque: {{ produto.diasDeEstoque
            }} </span></v-chip>
            <br />
            <v-divider class="ma-2 linha-endereco" />
          </div>
        </td>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.dataEnvio="props">
        {{ identificarTipoData(props.item.dataEnvio) }}<br />
        <span v-if="'logs' in props.item" class="c-logs">
          <strong>Usuário: </strong>{{ props.item.logs.email }}<br />
          <strong>Enviado Para: </strong>
          <span>
            <v-icon small @click="verLinkApi(props.item.logs.linkApi)">visibility</v-icon>
          </span>
        </span>
      </template>

    </v-data-table>
    <v-dialog v-model="dialogVerLink" hide-overlay width="300">
      <v-card dark color="sexta">
        <v-card-title class="text-body-2">
          Link API: {{ linkApiEnviado }}
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { urlApi, showError, cofingK } from "@/global";
import axios from "axios";
export default {
  name: "HistoricoInventario",
  props: { openHistorico: Boolean },
  components: {},
  data: () => ({
    loadingDownload: false,
    tipoDeDocumentoParaDownload: [],
    dialogVerLink: false,
    linkApiEnviado: '',
    dateFilter: {
      start: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().slice(0, 16),
      end: new Date().toISOString().slice(0, 16)
    },
    loopLoading: false,
    expanded: [],
    dataHistorico: [],
    h: [
      {
        text: "objectId",
        align: "start",
        sortable: true,
        value: "objectId",
        class: "u-hide",
        cellClass: "u-hide",
        width: '0%'
      },
      { align: "start", text: "Empresa", value: "empresa", width: '1%' },
      { align: "center", text: "Data do Envio", value: "dataEnvio", width: '5%' },
      { align: "center", text: "Periodo", value: "periodo", width: '5%' },
      { align: "center", text: "Produtos", value: "data-table-expand", width: '5%' },
      { align: "center", text: "Logs", value: "logs", width: '0%', class: "u-hide", cellClass: "u-hide",},
    ],
  }),
  computed: {
    ativarFiltragem() {
      if (this.openHistorico) {
        let sort = { item: 'dataEnvio', desc: true }
        return sort
      } else {
        let sort = { item: [], desc: false }
        return sort
      }
    },
    identificarTipoData() {
      return data => {
        let dataObjeto;
        let dataFormatada = '';
        let horaFormatada = '';

        if (data.includes('T')) {
          dataObjeto = new Date(data);

          if (!isNaN(dataObjeto)) {
            const dia = dataObjeto.getDate().toString().padStart(2, '0');
            const mes = (dataObjeto.getMonth() + 1).toString().padStart(2, '0');
            const ano = dataObjeto.getFullYear();

            dataFormatada = `${dia}/${mes}/${ano}`;

            const hora = dataObjeto.getHours().toString().padStart(2, '0');
            const minutos = dataObjeto.getMinutes().toString().padStart(2, '0');
            const segundos = dataObjeto.getSeconds().toString().padStart(2, '0');

            horaFormatada = `às ${hora}h:${minutos}min:${segundos}s`;
          }
        } else {
          dataObjeto = new Date(data);

          if (!isNaN(dataObjeto)) {
            const dia = dataObjeto.getDate().toString().padStart(2, '0');
            const mes = (dataObjeto.getMonth() + 1).toString().padStart(2, '0');
            const ano = dataObjeto.getFullYear();

            dataFormatada = `${dia}/${mes}/${ano}`;
          }
        }
        return `${dataFormatada} ${horaFormatada}`.trim();
      }
    },
    formatDataBr() {
      return (data => this.dataFormatadaBr(data))
    },
    dadosFiltrados() {
      let dfiltrado = this.dataHistorico.filter(item => {
        if (!this.dateFilter.start || !this.dateFilter.end) {
          return true; // Se não houver filtro de data definido, exibir todos os itens
        }

        const startDate = new Date(this.dateFilter.start);
        const endDate = new Date(this.dateFilter.end);
        const itemDate = new Date(item.dataEnvio); // Substitua 'item.date' pelo nome da propriedade de data no seu objeto de dados
        const isDateMatch = itemDate >= startDate && itemDate <= endDate;
        return isDateMatch
      })
      return dfiltrado
    }
  },
  watch: {
    openHistorico: {
      handler(novo) {
        if (novo) {
          this.listarHistorico();
          this.atualizarDataHoraModelo();
        } else {
          this.dataHistorico = []
        }
      },
      immediate: true,
    },
  },
  methods: {

    baixarArquivos() {
      const tiposSelecionados = this.tipoDeDocumentoParaDownload;

      // Verificar se o usuário selecionou algum tipo de documento
      if (!tiposSelecionados.includes(0) && !tiposSelecionados.includes(1) && !tiposSelecionados.includes(2)) {
        console.error('Por favor, selecione pelo menos um tipo de documento para download.');
        return;
      }

      // Definir a ordem dos tipos de documento para download
      const ordemDownload = ['CSV', 'HTML', 'PDF'];

      // Atualizar o loadingDownload para true
      this.loadingDownload = true;

      // Executar o download para cada tipo na ordem definida
      ordemDownload.forEach((tipo, index) => {
        if (tiposSelecionados.includes(index)) {
          switch (tipo) {
            case 'CSV':
              this.downloadCSV();
              break;
            case 'HTML':
              this.downloadHtmlEstilizado();
              break;
            case 'PDF':
              this.downloadPdfEstilizado();
              break;
            default:
              console.error(`Tipo de documento inválido: ${tipo}`);
          }
        }
        // Definir o loadingDownload de volta para false após o download estar completo
        this.loadingDownload = false;
      });
    },
    downloadPdfEstilizado() {
      if (!this.dateFilter || !this.dateFilter.start || !this.dateFilter.end) {
        console.error("dateFilter não está definido.");
        return;
      }

      const reversedData = this.dadosFiltrados.slice().reverse();

      // Função para extrair os dados dos produtos
      const extractProductData2 = (products) => {
        if (!products || products.length === 0) return ''; // Retornar vazio se não houver produtos

        const productData = products.map(product => {
          return `Produto: ${product.nomeProduto}; ${product.unidades} ${product.unidades > 1 ? "Unidades" : "Unidade"}; Fator: ${product.fator}; Dias de estoque: ${product.diasDeEstoque}<hr style="width: 300px; margin: 10px 0;">`;
        });

        return productData.join(''); // Não é necessário adicionar um separador no final
      };

      const htmlContent = `
    <html>
    <head>
      <title>Histórico Inventário</title>
      <style>
        table {
          width: 100%;
          border-collapse: collapse;
          font-size: 10px;
        }
        th, td {
          border: 1px solid black;
          padding: 5px;
          text-align: left;
          vertical-align: top; /* Alinhar o texto ao topo */
        }
        th {
          background-color: #f2f2f2;
          font-weight: bold; /* Aplicar negrito nos títulos */
        }
        .info-col, .datas-col, .produto-col {
          width: 33%; /* Definindo largura das colunas */
        }
      </style>
    </head>
    <body>
      <h2>Histórico Inventário</h2>
      <table>
        <thead>
          <tr>
            <th class="info-col">Informações</th>
            <th class="datas-col">Datas</th>
            <th class="produto-col">Produtos</th> <!-- Movida para o final -->
          </tr>
        </thead>
        <tbody>
          ${reversedData.map(item => `
            <tr>
              <td>
                <b>Empresa:</b> ${item.empresa}<br>
              </td>
              <td>
                <b>Data do Envio:</b> ${this.formatDataEnvio(item.dataEnvio)}<br>
                <b>Período:</b> ${this.formatDataBr(item.periodo)}<br>
              </td>
              <td>${extractProductData2(item.produtos)}</td>
            </tr>
          `).join('')}
        </tbody>
      </table>
    </body>
    </html>
  `;

      const container = document.createElement('div');
      container.innerHTML = htmlContent;

      const pdf = new jsPDF({ orientation: 'portrait', unit: 'pt', format: [800, 1100] });
      pdf.html(container, {
        callback: function (pdf) {
          pdf.save(`historico_inventario.pdf`);
        }
      });
    },
    downloadHtmlEstilizado() {
      // 1. Filtrar os dados com base no intervalo de data selecionado
      const filteredData = this.dadosFiltrados;

      // 2. Inverter a ordem dos dados para da mais nova para a mais antiga
      const reversedData = filteredData.slice().reverse();

      // 3. Construir o conteúdo HTML
      let htmlContent = `
    <html>
    <head>
      <title>Histórico Inventário</title>
      <style>
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
        th {
          background-color: #f2f2f2;
        }
      </style>
    </head>
    <body>
      <h2>Histórico Inventário</h2>
      <table>
        <thead>
          <tr>
            <th>Empresa</th>
            <th>Data do Envio</th>
            <th>Período</th>
            <th>Produtos</th>
          </tr>
        </thead>
        <tbody>
  `;

      reversedData.forEach(item => {
        htmlContent += `
      <tr>
        <td>${item.empresa}</td>
        <td>${this.formatDataEnvio(item.dataEnvio)}</td>
        <td>${this.formatDataBr(item.periodo)}</td>
        <td>${this.extractProductData2(item.produtos)}</td>
      </tr>
    `;
      });

      // Finalizar o conteúdo HTML
      htmlContent += `
        </tbody>
      </table>
    </body>
    </html>
  `;

      // 4. Criar um blob com o conteúdo HTML
      const blob = new Blob([htmlContent], { type: 'text/html' });

      // 5. Criar um link de download e simular o clique
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `historico_inventario_${this.dateFilter.start}_${this.dateFilter.end}.html`;
      link.click();
    },
    downloadCSV() {
      // 1. Filtrar os dados com base no intervalo de data selecionado
      const filteredData = this.dadosFiltrados;

      // 2. Inverter a ordem dos dados para da mais nova para a mais antiga
      const reversedData = filteredData.slice().reverse();

      // 3. Converter os dados para o formato desejado (por exemplo, CSV)
      const csvData = this.convertToCSV(reversedData);

      // 4. Criar um objeto Blob com os dados CSV
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

      // 5. Criar um link de download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `historico_inventario_${this.dateFilter.start}_${this.dateFilter.end}.csv`;

      // 6. Simular o clique no link de download
      link.click();
    },

    // Função auxiliar para converter os dados em CSV
    convertToCSV(data) {
      // Personalize os cabeçalhos e a extração de dados com base na estrutura dos seus dados
      const headers = [
        'Empresa',
        'Data do Envio',
        'Periodo',
        'Produtos'
      ];

      const rows = data.map(item => [
        item.empresa,
        this.formatDataEnvio(item.dataEnvio), // Formatar a data de envio
        this.formatDataBr(item.periodo), // Formatar o período
        this.extractProductData(item.produtos) // Extrair os dados dos produtos
      ]);
      return [headers, ...rows].map(row => row.join(',')).join('\n');
    },

    // Função auxiliar para extrair os dados dos produtos e formatá-los para CSV
    extractProductData(products) {
      if (!products || products.length === 0) return ''; // Retornar vazio se não houver produtos

      const productData = products.map(product => {
        return `Produto: ${product.nomeProduto}; ${product.unidades} ${product.unidades > 1 ? "Unidades" : "Unidade"}; Fator: ${product.fator}; Dias de estoque: ${product.diasDeEstoque}`
      });

      return productData.join('; '); // Separar os dados dos produtos por ponto e vírgula
    },
    extractProductData2(products) {
      if (!products || products.length === 0) return ''; // Retornar vazio se não houver produtos

      const productData = products.map(product => {
        return `Produto: ${product.nomeProduto}; ${product.unidades} ${product.unidades > 1 ? "Unidades" : "Unidade"}; Fator: ${product.fator}; Dias de estoque: ${product.diasDeEstoque}
        <hr style="width: 300px; margin: 10px 0;">`
      });

      return productData.join(' '); // Separar os dados dos produtos por ponto e vírgula
    },

    formatDataEnvio(data) {
      const dataObjeto = new Date(data);
      const dia = dataObjeto.getDate().toString().padStart(2, '0');
      const mes = (dataObjeto.getMonth() + 1).toString().padStart(2, '0');
      const ano = dataObjeto.getFullYear();
      const hora = dataObjeto.getHours().toString().padStart(2, '0');
      const minutos = dataObjeto.getMinutes().toString().padStart(2, '0');
      const segundos = dataObjeto.getSeconds().toString().padStart(2, '0');
      return `${dia}/${mes}/${ano} ${hora}h:${minutos}min:${segundos}s`;
    },


    verLinkApi(url) {
      this.linkApiEnviado = this.encurtarUrl(url)
      this.dialogVerLink = true
    },
    encurtarUrl(url) {
      const indiceSegundoHttp = url.indexOf("https://", url.indexOf("https://") + 1);
      const resultado = indiceSegundoHttp !== -1 ? url.slice(indiceSegundoHttp) : url;
      return resultado;
    },
    atualizarDataHoraModelo() {
      const agora = new Date();
      agora.setMinutes(agora.getMinutes() - agora.getTimezoneOffset() + 60);
      this.dateFilter.end = agora.toISOString().slice(0, 16);
      this.dateFilter.start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().slice(0, 16);
    },
    dataFormatadaBr(data) {
      const dataString = data;

      // Dividir a string da data em partes separadas
      const [ano, mes, dia] = dataString.split('-');

      // Formatar a data no formato desejado
      return `${dia}/${mes}/${ano}`;
    },
    listarHistorico() {
      axios
        .post(
          `${urlApi}/listar-historico-inventario`,
          {},
          {
            headers: cofingK,
          }
        )
        .then((res) => {
          this.dataHistorico = res.data.result;
          this.$toasted.global.defaultSuccess();
        })
        .catch(showError);
    },
  },
  created() { },
  beforeMount() { },
  mounted() { },
};
</script>

<style>
.c-col-botoes {
  border: solid 1px grey;
  border-radius: 5px;
}
.c-table-historico {
  display: inline-block;
  padding: 15px;
}

.c-table-historico__inventario table td {
  overflow-x: scroll;
  border: solid 1px #727272;
  font-size: 13px !Important;
}

.c-table-historico__inventario thead th {
  border: solid 1px #727272;
  padding: 0px 5px !important;
}

.c-logs {
  display: inline-block;
  overflow: hidden;
}

/* .c-table-historico__inventario thead th {
  border: solid 1px #727272;
  padding: 0px 5px !important;
  width: 260px !important;
} */
</style>