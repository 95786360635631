<template>
  <v-row justify="center" class="pt-8">
    <v-col cols="12" sm="6" md="3">
      <v-text-field v-model="vendas.notaFiscal" label="Nota Fiscal"></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <v-text-field outlined class="c-tab-produto__periodoRef" type="date" v-model="vendas.dataVenda"
        :value="vendas.dataVenda" label="Data da NF" hint="Data da Nota Fiscal" persistent-hint></v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <v-text-field outlined class="c-tab-produto__periodoRef" type="date" v-model="vendas.periodo"
        :value="vendas.periodo" label="Período da venda" hint="Período da venda" persistent-hint></v-text-field>
    </v-col>
    <v-col cols="12" sm="3" md="2">
      <v-select dark outlined v-model="vendas.tipoDeDocumento" :items="selecTipoDeDocumento" label="Tipo de Documento">
        <template v-slot:item="{ item }">
          <span v-if="item === 'CM'" class="primeira--text font-weight-bold caption">COMODATO - {{ item }}</span>
          <span v-if="item === 'CO'" class="primeira--text font-weight-bold caption">FATURAMENTO DA CONSIGNAÇÃO - {{ item
          }}</span>
          <span v-if="item === 'DT'" class="primeira--text font-weight-bold caption">DEVOLUÇÃO - {{ item }}</span>
          <span v-if="item === 'TR'" class="primeira--text font-weight-bold caption">TROCA OU BONIFICAÇÃO - {{ item
          }}</span>
          <span v-if="item === 'VE'" class="primeira--text font-weight-bold caption">VENDA - {{ item }}</span>
          <span v-if="item === 'CL'" class="primeira--text font-weight-bold caption">CANCELADA - {{ item }}</span>
        </template>
      </v-select>
    </v-col>
    <v-col cols="12" sm="12" md="12" class="ma-0 pa-0">
      <v-chip color="quinta" label class="rounded-b-0 mt-n1">Selecione os Produtos da Nota
      </v-chip>
      <vendasProdutos :zerarDados="zerarDados" @sendProdutos="getProdutos($event)"  @removerSelecao="dadosZerados($event)"></vendasProdutos>
    </v-col>
  </v-row>
</template>

<script>
import VendasProdutos from "./vendasProdutos.vue";
export default {
  name: "vendas-screen",
  props: { zerarDados: Boolean },
  components: { VendasProdutos },
  data: () => ({
    selecTipoDeDocumento: ["CM", "CO", "DT", "TR", "VE", "CL"],
    vendas: {
      notaFiscal: "",
      dataVenda: "",
      periodo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      tipoDeDocumento: "VE",
      produtos: [],
    },
  }),
  computed: {},
  watch: {
    vendas: {
      handler(novo) {
        this.sendDadosVenda(novo);
      },
      deep: true,
    },
    zerarDados: {
      handler(novo) {
        if (novo) {
          this.vendas.notaFiscal = "";
          this.vendas.dataVenda = "";
          this.vendas.tipoDeDocumento = "VE";
          this.vendas.periodo = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10);
        }
      },
      immediate: true,
    },
  },
  methods: {
    getProdutos(dados) {
      this.vendas.produtos = dados;
    },
    sendDadosVenda(dados) {
      this.$emit("sendDadosVenda", dados);
    },
    dadosZerados() {
      this.$emit("dadosZerados", true);
    },
  },
  created() { },
  beforeMount() { },
};
</script>

<style></style>
