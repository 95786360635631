import Vue from 'vue'
export default new Vue({
    methods: {
        enviarNovosUsuarios(novosUser) {
            this.$emit('getNovosUsuarios', novosUser)
        },
        getNovosUsuarios(callback) {
            this.$on('getNovosUsuarios', callback)
        },
        enviarStatusLogin(isLogin, isSessaoExpirada) {
            this.$emit('getStatusLogin', isLogin, isSessaoExpirada)
        },
        getStatusLogin(callback) {
            this.$on('getStatusLogin', callback)
        },
        enviarAltenticacao(isAltenticado) {
            this.$emit('getAltenticacao', isAltenticado)
        },
        getAltenticacao(callback) {
            this.$on('getAltenticacao', callback)
        },
        enviarOpenInventario(isAltenticado) {
            this.$emit('getAltenticacao', isAltenticado)
        },
        getOpenInventario(callback) {
            this.$on('getAltenticacao', callback)
        },
    }
})