<!-- eslint-disable -->
<template>
  <div>
    <v-data-table v-model="produtosSelecionados" :items="produtos" :single-select="selecaoUnica"
      :headers="cabecalhoTabela" sort-by="material" item-key="objectId" :search="search"
      class="quinta elevation-1 pt-5 c-tabela-produtos" show-select calculate-widths :loading="custoLoading"
      :loader-height="0" @item-selected="identificarSelecao">
      <template v-slot:top color="primeira">
        <v-progress-linear absolute top :height="8" :active="custoLoading" :indeterminate="custoLoading" color="#727272"
          class="pt-1"></v-progress-linear>
        <v-toolbar flat color="quinta">
          <v-toolbar-title>Produto</v-toolbar-title>
          <v-text-field class="mx-3" v-model="search" append-icon="mdi-magnify" label="Buscar Produto" single-line
            hide-details></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:header.material="props">
        {{ props.header.text }}
      </template>

      <template v-slot:header.qtdPorCx="props">
        {{ props.header.text }}
      </template>

      <template v-slot:header.unidadeMedida="props">
        {{ props.header.text }}
      </template>

      <!-- Dados da tabela -->
      <template v-slot:item.unidade="props">
        <v-text-field
          hide-details
          :filled="props.item.naoSelecionado"
          :disabled="props.item.naoSelecionado"
          type="number"
          v-model.number="props.item.unidade"
          single-line
          prepend-icon="remove"
          append-icon="add"
          @click:append="props.item.unidade++" @click:prepend="props.item.unidade--"
          @keyup="removTeclaE($event, props.item)">
      </v-text-field>
      </template>

      <template v-slot:item.qtdPorCx="props">
        <v-text-field hide-details :filled="props.item.statusUnidadeMedida" :disabled="props.item.statusUnidadeMedida"
          type="number" v-model.number="props.item.qtdPorCx" single-line prepend-icon="remove" append-icon="add"
          @click:append="props.item.qtdPorCx++" @click:prepend="props.item.qtdPorCx--"
          @keyup="removTeclaEqtdPorCx($event, props.item)"></v-text-field>
      </template>

      <template v-slot:item.unidadeMedida="props">
        <v-select
        hide-details :key="props.item.objectId"
        :filled="props.item.naoSelecionado"
        :disabled="props.item.naoSelecionado"
        dense v-model="props.item.unidadeMedida" outlined color="sexta"
          :items="props.item.opUnidadeMedida" @input="isTrueUniMedida($event, props.item)" class="c-select-un">
          <template v-slot:selection="{ item }">
            {{ Object.values(item)[0] }}
          </template>
          <template v-slot:item="{ item }">
            <v-card width="100%" flat class="ma-0 pa-0" color="quinta">
              <v-list dark dense color="quinta" class="ma-0 pa-0">
                <v-list-item v-for="(valor, idxKey) in item" :key="idxKey">
                  <v-list-item-title class="font-weight-bold pa-0 ma-0">{{ valor }} - {{ idxKey }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </template>
        </v-select>
      </template>
      <template v-slot:item.material="{ item }">{{ item.material }}</template>

      <!--     Botão para carregar produtos -->
      <template v-slot:no-data>
        <v-btn color="primeira" @click="initialize">Carregar dados</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import { urlApi, showError, cofingK } from "@/global";
export default {
  name: "tabelaClientes",
  props: { zerarDados: Boolean },
  data: () => ({
    quantiLinhas: 0,
    custoLoading: false,
    inventario: {},
    produtosSelecionados: [],
    selecaoUnica: false,
    search: "",
    cabecalhoTabela: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "objectId",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Nome do Produto",
        align: "start",
        sortable: true,
        value: "material",
        width: "40%",
      },
      {
        text: "Unidade de Medida",
        align: "center",
        sortable: true,
        value: "unidadeMedida",
        cellClass: "py-4",
        width: "15%",
      },
      {
        text: "Quant. Por Caixa",
        align: "center",
        sortable: true,
        value: "qtdPorCx",
        cellClass: "py-4",
        width: "20%",
      },
      {
        text: "Unidades Vendidas",
        align: "center",
        sortable: true,
        value: "unidade",
        cellClass: "c-tab-produto__col__input",
        width: "20%",
      },
      {
        text: "Código",
        sortable: false,
        value: "codigo",
        class: "u-hide",
        cellClass: "u-hide",
        width: "0%",
      },
    ],
    produtos: [],
    quantEstoque: 0,
    editedIndex: -1,
    editedItem: {
      material: "",
      codigo: "",
      qtdPorCx: 0,
      unidade: 0,
    },
    defaultItem: {
      material: "",
      codigo: "",
      qtdPorCx: 0,
      unidade: 0,
    },
  }),

  computed: {},

  watch: {
    zerarDados: {
      handler(novo) {
        if (novo) {
          this.produtosSelecionados = []
          this.dadosZerados()
        }
      },
      immediate: true
    },
    produtosSelecionados: {
      handler(novo) {
        this.sendProdutos(novo);
      },
      deep: true,
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    dadosZerados() {
      this.$emit("removerSelecao", true);
    },
    /* 
    PARA APLICAR CLASSE IMPAR OU PAR DEPENDENDO DA SELEÇÃO SE O NÚMERO FOR IMPA OU PAR. NÃO APAGUE
    isPar(numero, naoSelecionado) {
      if(!naoSelecionado){
        return numero % 2 === 0;
      }else {
        return false
      }
    },
    itemClass(item) {
      return this.isPar(this.produtosSelecionados.findIndex(i => i.objectId === item.objectId), item.naoSelecionado) ? {'corImpa': true} : {'corPar': true}
    }, */
    identificarSelecao(linha) {
      if (linha.value) {
        linha.item.naoSelecionado = false
      } else {
        linha.item.naoSelecionado = true
      }
    },
    isTrueUniMedida(e, ele) {
      if (Object.values(e)[0] === "CX") {
        ele.statusUnidadeMedida = false;
      } else {
        ele.statusUnidadeMedida = true;
      }
    },
    sendProdutos(dados) {
      this.$emit("sendProdutos", dados);
    },
    removTeclaEqtdPorCx(e, v) {
      if (e.key === "e") v.qtdPorCx = 0;
    },
    removTeclaE(e, v) {
      if (e.key === "e") v.unidade = 0;
    },
    initialize() {
      this.custoLoading = true;
      axios
        .post(`${urlApi}/listar-produtos`, this.user, { headers: cofingK })
        .then((res) => {
          res.data.result.forEach((el) => {
            el.unidadeMedida = { Unidade: "UN" };
            el.opUnidadeMedida = [
              { Litros: "LT" },
              { Unidade: "UN" },
              { Sache: "SA" },
              { Display: "DI" },
              { Kilos: "KG" },
              { Caixa: "CX" },
            ];
            el.statusUnidadeMedida = true;
            el.naoSelecionado = true;
          });
          this.produtos = res.data.result;
          this.custoLoading = false;
        })
        .catch(showError);
    },
  },
};
</script>

<style>
.c-tabela-produtos th>.v-simple-checkbox {
  display: none !important;
}

.v-data-table__selected {
  background: #3331ab !important
}

.c-tab-produto__col__input input::-webkit-outer-spin-button,
.c-tab-produto__col__input input::-webkit-inner-spin-button {
  display: none;
  margin: 0px;
}

.c-tab-produto__col__input input[type="number"] {
  -moz-appearance: textfield;
}

.c-tab-produto__periodoRef input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  margin: 0px;
  padding: 0px;
}

.c-tabela-produtos {
  position: relative;
}

.c-select-un .v-select-list {
  background: #585858;
}
</style>
